/// <reference path="../../../app-common/app-core/app-core.js" />
/// <reference path="../../../app-common/app-core-ajax/app-core-ajax.js" />

(function (ns) {
    "use strict";

    (function (payment) {
        payment.initPaymentsTab = function (viewData, resizeCallback) {
            $("[name='Date']")
                .attr("type", "text")
                .datepicker({
                    endDate: "0d",
                    todayHighlight: true
                });

            var RequestPaymentViewModel = function (requestId, organization, orgId) {
                var self = this;
                self.request = { id: requestId };
                self.organization = ko.observable(organization);
                self.selectedOrgId = ko.observable(orgId);
                self.payments = ko.observableArray();
                self.payment = ko.observable().extend({
                    validation: {
                        validator: function (val) {
                            return val;
                        },
                        message: "Выберите элемент из списка.",
                    }
                });
                self.sum = ko.observable().extend({
                    validation: {
                        validator: function (val) {
                            var floatVal = parseFloat(String(val).replace(',', '.'));
                            return !self.payment() || (floatVal >= 0.01 && floatVal <= self.payment().balance);
                        },
                        message: function () {
                            return "Поле должно принимать значения от 0.01 до " + self.payment().balance;
                        },
                    }
                });
                self.sumInUnit = ko.pureComputed(function () {
                    if (self.sum() && self.payment() && self.payment().unitRate) {
                        var floatVal = String(self.sum()).replace(',', '.')
                        return (floatVal * self.payment().unitRate).toFixed(3);
                    }
                    return "";
                });
                self.payment.subscribe(function (val) {
                    if (val) self.sum(val.balance);
                    else self.sum("");
                });

                self.updatePayments = function () {
                    if (!self.selectedOrgId()) {
                        viewModel.errorsRequestPayment.showAllMessages(false);
                        return;
                    }
                    var org = self.selectedOrgId();
                    self.payments.removeAll();
                    $.get(viewData.findPaymentsUrl, { organizationId: org }, function (data) {
                        self.payments(data);
                    });
                };

                self.organization.subscribe(self.updatePayments);
                self.selectedOrgId.subscribe(self.updatePayments);
            };

            var ViewModel = function () {
                var self = this;

                var org = viewData.organization;
                org.id = null;
                org.name = "";
                self.requestPaymentModel = ko.validatedObservable(new RequestPaymentViewModel(viewData.requestId, org, viewData.organization.id));
                self.organizations = ko.observableArray(viewData.organizations);
                self.payments = ko.observableArray();
                self.guarantee = ko.observable();
                self.preferential = ko.observable();
                self.errorsRequestPayment = ko.validation.group(self.requestPaymentModel, { deep: true });

                self.orgClicked = function (item) {
                    $("[name = 'otherOrgInput']").prop("disabled", true);
                    return true;
                }

                self.fieldClicked = function () {
                    $("[name = 'otherOrgInput']").prop("disabled", false);
                    return true;
                }

                self.requestSum = ko.observable(viewData.requestSum);

                self.tableSumInUnit = ko.computed(function () {
                    var sum = 0;
                    for (var i = 0; i < self.payments().length; i++) {
                        sum += self.payments()[i].sumInUnit;
                    }
                    return Math.round(sum * 1000) / 1000;
                });

                self.calculationSumUnit = viewData.calculation.sum;
                self.deliverySum = viewData.calculation.deliverySum;
                self.unitPay = viewData.calculation.unitPayType.text;

                self.isPaymentEquals = ko.computed(function () {
                    return self.preferential() || (self.tableSumInUnit() === self.calculationSumUnit && self.deliverySum === 0);
                });

                self.paymentEqualityMark = ko.computed(function () {
                    return self.isPaymentEquals() ? "=" : "≠";
                });

                self.deficit = ko.computed(function () {
                    if (self.requestPaymentModel().payment()) {
                        var balance = self.requestPaymentModel().payment().balance;
                        var deficit = (self.calculationSumUnit - self.tableSumInUnit()) / self.requestPaymentModel().payment().unitRate;
                        if (deficit > 0)
                            deficit = Math.round(deficit * 100) / 100;
                        else
                            deficit = 0;
                        self.requestPaymentModel().sum(balance > deficit ? deficit : balance);
                    }
                    return 0;
                });

                self.deletePayment = function (payment) {
                    var isDelete = confirm(ns.resources.getResource("removePayment"));
                    if (isDelete) {
                        $.post(viewData.deletePaymentUrl, { paymentId: payment.id, requestId: payment.requestId }, function (data) {
                            ns.messages.loadMessages();
                            self.updatePayments();
                        });
                    }
                    return false;
                };

                self.updatePayments = function () {
                    console.log("update");
                    $.post(viewData.getRequestsPaymentsUrl, function (data) {
                        self.payments.removeAll();
                        for (var i = 0; i < data.length; i++)
                            self.payments.push(data[i]);
                        self.requestPaymentModel().updatePayments();
                    });
                };

                self.loadConditions = function () {
                    $.post(viewData.getConditionsUrl, function (data) {
                        self.guarantee(data.guarantee);
                        self.preferential(data.preferential);
                    });
                };
                self.processPayment = function () {
                    if (!self.requestPaymentModel.isValid()) {
                        self.errorsRequestPayment.showAllMessages(true);
                        return false;
                    }
                    else {
                        $.ajax({
                            type: "POST",
                            url: viewData.addRequestPaymentUrl,
                            dataType: "json",
                            contentType: "application/json",
                            data: ko.toJSON(self.requestPaymentModel),
                            success: function (responseData) {
                                if (responseData.success) {
                                    self.updatePayments();
                                }
                                ns.messages.loadMessages();
                            }
                        });
                    }
                };
            };

            var viewModel = new ViewModel();
            ko.applyBindings(viewModel, $("#payment-tab")[0]);
            viewModel.updatePayments();
            viewModel.loadConditions();
            initAutocomplete($("#organization"), viewModel.requestPaymentModel().organization, viewData.findOrganizationsUrl);

            function initAutocomplete($input, observable, url) {
                ns.autocomplete.init($input, {
                    source: function (request, response) {
                        $.ajax({
                            url: url,
                            dataType: "json",
                            data: {
                                term: request.term
                            },
                            success: function (data) {
                                response(data);
                            }
                        });
                    },
                    minLength: 3,
                    fixed: true,
                    select: function (event, ui) {
                        observable(ui.item);
                    },
                });
                $input.on("clear", function () {
                    observable(null);
                });
            }
            /*  (function initResizing() {
                  var permitPaymensResizeHandler = function(event, ui) {
                      var heightDiff = 280;
                      var newHeight = ui.element.height();
                      var newPanelHeight = newHeight - heightDiff;
  
                      var $elements = $(".tab-pane#Payments").find(".resizable-element");
                      $elements.height(newPanelHeight);
                  };
                  resizeCallback(permitPaymensResizeHandler);
              })();*/
            $("#add-payment").click(function (e) {
                var orgRadios = document.getElementsByName("orgRadio");
                var selectedOrg = Array.from(orgRadios).filter(function (x) { return x.checked == true });
                var url = $(this).data("url");
                url += '&' + "orgId=" + selectedOrg[0].value;
                ns.payment.PaymentEdit(url, null, function (payment) {
                    viewModel.requestPaymentModel().organization(payment.organization);
                });
            });

        }
    }(ns.payment = ns.payment || {}));

}($.app = $.app || {}));