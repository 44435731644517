/// <reference path="../../app-common/app-core/app-core.js" />
/// <reference path="../../app-common/app-core-widgets/app-dialog.js" />
/// <reference path="../../../bower_components/knockout/dist/knockout.debug.js" />
/// <reference path="../../../bower_components/knockout-validation/dist/knockout.validation.js" />

(function (ns) {
    "use strict";

    (function (nsSpecific) {
        nsSpecific.SpecialPermitViewModel = function (viewData, datepickerOptions) {
            var model = viewData.model;
            var self = this;

            self.series = model.seriesPermit;
            self.isReusable = ko.observable(model.isReusable);
            self.requestId = model.requestId;
            self.hasSeriesNumber = ko.observable(/*!model.numberPermit == !model.issuedWho ? true : */false);
            if (viewData.isSigned)
            {
                self.issuedOnPaper = ko.observable(false);
                self.SPType = ko.observable("EV").extend({
                    required: {
                        message: "Поле обязательно для заполнения!"
                    }
                });
                $("#issuePermitBtn").css("background-color", "darkgreen").html('<i class="fas fa-check"></i>  Выдать в электронной форме ');
            }
            else
            {
                self.issuedOnPaper = ko.observable(true);
                self.SPType = ko.observable("PV").extend({
                    required: {
                        message: "Поле обязательно для заполнения!"
                    }
                });
                $("#issuePermitBtn").css("background-color", "maroon").html('<i class="fas fa-check"></i>  Выдать на бумажном носителе ');
            }
            
            self.SPType.subscribe(function(v) {
                console.log(v);                
                if (v == "EV") {
                    self.issuedOnPaper(false);
                    self.hasSeriesNumber(false);
                    $("#issuePermitBtn").css("background-color", "darkgreen").html('<i class="fas fa-check"></i>  Выдать в электронной форме ');
                }
                if (v == "PV") {
                    self.issuedOnPaper(true);
                    self.hasSeriesNumber(false);
                    $("#issuePermitBtn").css("background-color", "maroon").html('<i class="fas fa-check"></i>  Выдать на бумажном носителе ');
                }
                if (v == "SB") {
                    self.issuedOnPaper(true);
                    self.hasSeriesNumber(true);
                }
            });


            self.number = ko.observable(model.numberPermit)
                .extend({
                    digit: {
                        params: true,
                        message: "Номер разрешения должен содержать только цифры"
                    },
                    maxLength: {
                        params: 7,
                        message: "Длина номера разрешения должна быть {0} символов"
                    },
                    minLength: {
                        params: 7,
                        message: "Длина номера разрешения должна быть {0} символов"
                    },
                    required: {
                        params: true,
                        message: ns.resources.getResource("required"),
                        onlyIf: self.issuedOnPaper && self.hasSeriesNumber
                    }
                });

            self.blackList = ko.observable(model.blackList);

            self.tripDays = ko.observable(model.tripDays ? model.tripDays : 30)
                .extend({
                    digit: {
                        params: true,
                        message: "Поле 'Срок действия' может принимать значения от 1 до 30!",
                        onlyIf: validateTripDays
                    },
                    max: {
                        params: 30,
                        message: "Поле 'Срок действия' может принимать значения от 1 до 30!",
                        onlyIf: validateTripDays
                    },
                    min: {
                        params: 1,
                        message: "Поле 'Срок действия' может принимать значения от 1 до 30!",
                        onlyIf: validateTripDays
                    },
                    required: {
                        params: true,
                        message: "Поле 'Срок действия' обязательно для заполнения!",
                        onlyIf: validateTripDays
                    }
                });
            self.tripMonths = model.countTrip;

            self.validFromDate = ko.observable(new Date(model.validFromDate) > new Date() ? new Date(model.validFromDate) : new Date(datepickerOptions.startDate))
                .extend({
                    required: {
                        message: "Поле 'Дата начала действия' обязательно для заполнения!"
                    }
                });

            self.validToDate = ko.pureComputed(function () {
                var validFromDate = new Date(self.validFromDate());
                var validToDate = new Date(validFromDate);
                return self.isReusable()
                    ? ns.date.addMonths(validToDate, ns.parseNumber(self.tripMonths), true)
                    : ns.date.addDays(validToDate, ns.parseNumber(self.tripDays()), true);
            });

            self.route = ko.observable(model.routeText);

            if (datepickerOptions) {
                self.datepickerOptions = {
                    startDate: datepickerOptions.startDate ? new Date(datepickerOptions.startDate) : null,
                    endDate: datepickerOptions.endDate ? new Date(datepickerOptions.endDate) : null
                };
            }

            self.displayTripDates = ko.pureComputed(function () {
                var formatter = Globalize.dateFormatter();
                var validFromDate = new Date(self.validFromDate());
                var validToDate = new Date(self.validToDate());
                return 'c ' + formatter(validFromDate) + ' по ' + formatter(validToDate);
            });

            function validateTripDays() {
                return !self.isReusable();
            }
        };
    }(ns.viewModels = ns.viewModels || {}));

}($.app = $.app || {}));
