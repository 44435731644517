/// <reference path="../../../../app-common/app-core/app-core.js" />
/// <reference path="../../../../app-common/app-core-ajax/app-core-ajax.js" />
/// <reference path="../../../../app-common/app-core-widgets/app-autocomplete.js" />
/// <reference path="../../../../../bower_components/knockout/dist/knockout.debug.js" />
/// <reference path="../../../../../bower_components/bootstrap-datepicker/dist/js/bootstrap-datepicker.js" />
/// <reference path="../../../../../bower_components/knockout-validation/dist/knockout.validation.js" />

(function(ns) {
    "use strict";

    (function(payment) {
        payment.init_PaymentList_SearchFilter = function() {
            var options = JSON.parse($("#search-filter").attr("data-options"));
            var FilterViewModel = function() {
                var self = this;
                self.filter = {
                    organizationId: ko.observable(),
                    startDate: ko.observable(new Date(options.startDate)),
                    endDate: ko.observable(new Date(options.endDate)),
                    documentNumber: ko.observable(),
                    userId: ko.observable(options.userId),
                    
                    datepickerOptions : {
                        endDate: options.endDate ? new Date(options.endDate) : null
                    }
                }
                self.currentFilterState = ko.computed(function() {
                    return ko.toJS(self.filter);
                });
            }
            var filterViewModel = new FilterViewModel();          
            ko.applyBindings(filterViewModel, $("#search-filter")[0]);
            initAutocompletes(filterViewModel, options);
        }

        function initAutocompletes(filterViewModel, options) {

            initOrganizationAutocomplete($("#organization"), filterViewModel.filter.organizationId);

            function initOrganizationAutocomplete($input, observable) {
                ns.autocomplete.init($input,
                    {
                        source: options.findOrganizationsUrl,
                        minLength: 3,
                        fixed: true,
                        select: function(event, ui) {
                            observable(ui.item.id);
                        }
                    });
                $input.on("clear", function() {
                    observable(null);
                });
            }
        }

    }(ns.payment = ns.payment || {}));

}($.app = $.app || {}));