/// <reference path="../../../app-common/app-core/app-core.js" />
/// <reference path="../../../app-common/app-core-ajax/app-core-ajax.js" />

(function(ns) {
    "use strict";

    (function(payment) {
        payment.PaymentEdit = function(url, paymentId, callBackFunction) {

            $.ajax({
                url: url,
                method: "POST",
                data: { paymentId: paymentId },
                success: function(response) {
                    ns.messages.loadMessages();
                    if (response)
                        initModal(response);
                }
            });

            function initModal(message) {

                var $dialog = ns.dialogs.openDialog({
                    title: "Платежный документ",
                    message: message
                });

                var viewData = JSON.parse($dialog.find("#view-data").val());

                var Organization = function() {
                    var self = this;
                    self.id = ko.observable(viewData.model.organization.id);
                    self.name = ko.observable(viewData.model.organization.name);
                };

                var PaymentViewModel = function() {
                    var self = this;

                    self.id = paymentId;
                    self.datepickerOptions = {
                        endDate: viewData.today ? new Date(viewData.today) : null,
                        startDate: viewData.startDate ? new Date(viewData.startDate) : null,
                    };
                    self.date = ko.observable(new Date(viewData.model.date));
                    self.basis = ko.observable(viewData.model.basis);
                    self.basisDocumentNumber = ko.observable(viewData.model.basisDocumentNumber);
                    self.currency = ko.observable(viewData.model.currency);
                    self.isBYNOnly = viewData.model.isBelarusian || viewData.isBaseValue;
                    self.sum = ko.observable(viewData.model.sum);
                    self.organization = new Organization();
                    self.currencies = ko.computed(function() {
                        if (self.isBYNOnly)
                            return viewData.currencies.filter(function(el) { if (el.value.toUpperCase() === "BYN") return el; });
                        else
                            return viewData.currencies.filter(function(el) { if (el.value.toUpperCase() !== "BYN") return el; });
                    });
                };

                var ViewModel = function() {
                    var self = this;
                    self.paymentModel = new PaymentViewModel();
                    self.loadPayment = function(formElement) {
                        if (!$(formElement).valid()) {
                            return false;
                        }
                        $.ajax({
                            url: viewData.saveUrl,
                            type: "POST",
                            contentType: "application/json",
                            data: ko.toJSON(self.paymentModel),
                            success: function(responseData) {
                                if (responseData.success) {
                                    callBackFunction(responseData.payment);
                                    ns.dialogs.closeDialog($dialog);
                                }
                                ns.messages.loadMessages();
                            }
                        });
                    };
                };

                var viewModel = ko.validatedObservable(new ViewModel());
                ko.applyBindings(viewModel, $("#add-payment-form")[0]);
                initAutocomplete($("#Organization"), viewModel().paymentModel.organization.id);

                function initAutocomplete($input, observable) {
                    ns.autocomplete.init($input,
                        {
                            source: viewData.findOrganizationsUrl,
                            minLength: 3,
                            fixed: true,
                            select: function(event, ui) {
                                observable(ui.item.id);
                            }
                        });
                    $input.on("clear", function() {
                    });
                }
            }
        };

    }(ns.payment = ns.payment || {}));

}($.app = $.app || {}));