/// <reference path="../app-core/app-core.js" />


(function (ns) {
    "use strict";

    (function (validation) {

        validation.waitValidation = function ($form, validationSuccessCallback, validationFailureCallback) {
            $form.valid();      // Trigger validation

            var intervalHandle = setInterval(function () {
                checkIfValidated();
            }, 5);

            function checkIfValidated() {
                if ($form.data("validator").pendingRequest === 0) {
                    clearInterval(intervalHandle);
                    
                    if ($form.valid()) {
                        if ($.isFunction(validationSuccessCallback))
                            validationSuccessCallback();
                    } else {
                        if ($.isFunction(validationFailureCallback))
                            validationFailureCallback();
                    }
                }
            }
        }

    }(ns.validation = ns.validation || {}));

}($.app = $.app || {}));
