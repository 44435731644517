/// <reference path="../../app-common/app-core/app-core.js" />
/// <reference path="../../app-common/app-core/app-resources.js" />
/// <reference path="../../app-common/app-core-ajax/app-core-ajax.js" />
/// <reference path="../../app-common/app-core-widgets/app-dialog.js" />
/// <reference path="../../app-common/app-validation/app-validation-remote.js" />
/// <reference path="../app-core/app-config.js" />
/// <reference path="../../../bower_components/knockout/dist/knockout.debug.js" />

(function(ns) {
    "use strict";

    (function(organizations) {

        function initDialog(template) {
            var $dialog = ns.dialogs.openDialog({
                message: template,
                size: "large",
                title: ns.resources.getResource("addingOrganization"),
                onEscape: function() {

                },
                buttons: {
                    back: {
                        label: ns.resources.getResource("back"),
                        className: "btn-default btn-back",
                        callback: function() {
                            return false;
                        }
                    },
                    next: {
                        label: ns.resources.getResource("next"),
                        className: "btn-primary btn-next",
                        callback: function() {
                            return false;
                        }
                    },
                    save: {
                        label: ns.resources.getResource("save"),
                        className: "btn-primary btn-save",
                        callback: function() {
                            return false;
                        }
                    }
                }
            });
            $dialog.find(".btn-back").attr("data-bind", "click: goPrevious, visible: currentIndex() > 0");
            $dialog.find(".btn-next").attr("data-bind", "click: goNext, visible: currentIndex() < stepModels().length - 1");
            $dialog.find(".btn-save").attr("data-bind", "click: save, visible: currentIndex() === stepModels().length - 1");
            return $dialog;
        }

        function applyBindings($dialog, options, organizationViewModel, successCallback) {
            ko.validation.rules["remoteOrgNameValidationRule"] = {
                async: true,
                validator: function(val, params, callback) {
                    viewModel.dataModel().showEditOrganization(false);
                    $.ajax({
                        url: options.validateOrgNameUrl,
                        type: "POST",
                        data: {
                            name: val
                        },
                        success: function(data) {                            
                            if (!data.isValid) {
                                viewModel.dataModel().savedOrganization(data.savedOrganization);
                                viewModel.dataModel().showEditOrganization(true);
                            }
                            else {
                                if (viewModel.dataModel().organizationForm() == "BranchOfficeWithoutBankAccount") {
                                    viewModel.dataModel().hasName(true);
                                    viewModel.dataModel().name(val);
                                    viewModel.dataModel().fullName(val);
                                }
                            }
                            callback({
                                isValid: data.isValid,
                                message: data.errorMessage
                            });
                        }
                    });
                }
            };
            ko.validation.registerExtenders();

            function Step(id, template, errorFunc, asyncValidatedObservables, interruptFunc) {
                var self = this;

                self.id = id;
                self.template = template;
                self.errorFunc = errorFunc;

                self.interruptFunc = function() {
                    if ($.isFunction(interruptFunc)) {
                        return interruptFunc();
                    }
                    return false;
                };

                self.isValid = function() {
                    var hasErrors = self.errorFunc().length > 0;
                    if (hasErrors) {
                        console.log(self.errorFunc());
                        self.errorFunc.showAllMessages();
                    }
                    return !hasErrors;
                };

                self.isValidating = function() {
                    if (!$.isArray(asyncValidatedObservables)) {
                        return false;
                    }

                    for (var i = 0; i < asyncValidatedObservables.length; i++) {
                        if (asyncValidatedObservables[i].isValidating()) {
                            return true;
                        }
                    }
                    return false;
                };
            }

            function WizardViewModel() {
                var self = this;


                self.dataModel = ko.validatedObservable(new ns.organizations.OrganizationViewModel($dialog, options, null));
                
                self.stepModels = ko.observableArray([
                    new Step("basicInfo", "basic-organization-info-template", 
                        ko.validation.group([
                            self.dataModel().unp,
                            self.dataModel().organizationForm,
                            self.dataModel().country
                        ], { deep: true }),
                        [self.dataModel().unp]),

                    new Step("branchInfo", "branch-info-template",
                        ko.validation.group(self.dataModel().branchInfo, { deep: true }),
                        null,
                        function() {
                            if (self.dataModel().branchInfo.branchSource() === "list" && self.dataModel().branchInfo.selectedBranch()) {
                                successCallback(self.dataModel().branchInfo.selectedBranch());
                                ns.dialogs.closeDialog($dialog);
                                return true;
                            }
                        }),

                    new Step("details", "organization-details-template",
                        ko.validation.group([self.dataModel().name, self.dataModel().address], { deep: true }))
                ]);

                self.currentStep = ko.observable(self.stepModels()[0]);

                self.currentIndex = ko.computed(function() {
                    return self.stepModels.indexOf(self.currentStep());
                });

                self.getTemplate = function(data) {
                    return self.currentStep().template;
                };

                self.canGoNext = ko.computed(function() {
                    return self.currentIndex() < self.stepModels().length - 1;
                });

                self.canGoPrevious = ko.computed(function() {
                    return self.currentIndex() > 0;
                });

                self.goNext = function() {
                    if (self.currentStep().isValidating()) {
                        setTimeout(function() {
                            self.goNext();
                        }, 50);
                        return false;
                    }
                    var isValidStep = self.currentStep().isValid();
                    if (isValidStep && self.canGoNext()) {
                        if (self.currentStep().interruptFunc()) {
                            return false;
                        }
                        if (self.currentStep().id === "basicInfo" && !self.dataModel().isBranchInfoRequired()) {
                            self.currentStep(self.stepModels()[self.currentIndex() + 2]);
                        } else {
                            self.currentStep(self.stepModels()[self.currentIndex() + 1]);
                        }
                    }
                };

                self.goPrevious = function() {
                    if (self.canGoPrevious()) {
                        if (self.currentStep().id === "details" && !self.dataModel().isBranchInfoRequired())
                            self.currentStep(self.stepModels()[self.currentIndex() - 2]);
                        else
                            self.currentStep(self.stepModels()[self.currentIndex() - 1]);
                    }
                };

                self.save = function() {
                    if (self.currentStep().isValidating()) {
                        setTimeout(function() { self.save(); }, 50);
                        return false;
                    }
                    var isValidStep = self.currentStep().isValid();
                    if (isValidStep) {
                        $.ajax({
                            url: options.saveOrganizationUrl,
                            contentType: "application/json",
                            type: "POST",
                            data: ko.toJSON(self.dataModel),
                            success: function(data) {
                                if (data.isValid) {
                                    // successCallback(data.org);
                                    ns.dialogs.closeDialog($dialog);
                                }
                                ns.messages.loadMessages();
                            }
                        });
                    }
                };

            }

            var viewModel = new WizardViewModel();
            ko.applyBindings(viewModel, $dialog[0]);
        }

        organizations.addOrganizationWizard = function(url, organizationViewModel, successCallback) {
            $.get(url,
                function(responseData) {
                    var $dialog = initDialog(responseData);
                    var options = JSON.parse($dialog.find("#options").val());
                    applyBindings($dialog, options, organizationViewModel, successCallback);
                });
        };

    }(ns.organizations = ns.organizations || {}));
}($.app = $.app || {}));