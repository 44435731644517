/// <reference path="../app-core/app-core.js" />

(function (ns) {
    "use strict";

    ns.addInitFunction(function () {

        /***** ПРЕДОТВРАЩЕНИЕ ДВОЙНОГО SUBMIT'А****/

        // Отключить submit-кнопку после submit'а
        var $submitButton;
        $(document).on("submit", "form", function () {
            $submitButton = $(this).find('input[type="submit"]');

            setTimeout(function () {
                $submitButton.attr("disabled", "disabled");
            }, 0);
        });

        // Включить кнопку после завершения ajax-запроса
        $(document).ajaxStop(
            function () {
                if ($submitButton) {
                    $submitButton.removeAttr("disabled");
                }
            });

        // Включить кнопку при неуспешной валидации
        $(document).on("invalid-form.validate", "form",
                function () {
                    var button = $(this).find('input[type="submit"]');
                    setTimeout(function () {
                        button.removeAttr("disabled");
                    }, 1);
                });

    });


}($.app = $.app || {}));