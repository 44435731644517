(function ($) {
    "use strict";
    
    (function (locale) {

        var translations = {
            formatMultipleSort: function () {
                return "Множественная сортировка";
            },
            formatAddLevel: function () {
                return "Добавить уровень";
            },
            formatDeleteLevel: function () {
                return "Удалить уровень";
            },
            formatColumn: function () {
                return "Столбец";
            },
            formatOrder: function () {
                return "Порядок";
            },
            formatSortBy: function () {
                return "Сортировать по";
            },
            formatThenBy: function () {
                return "Затем по";
            },
            formatSort: function () {
                return "Сортировать";
            },
            formatCancel: function () {
                return "Отмена";
            },
            formatDuplicateAlertTitle: function () {
                return "Обнаружены дублирующиеся поля!";
            },
            formatDuplicateAlertDescription: function () {
                return "Пожалуйста, удалите дублирующиеся столбцы.";
            },
            formatSortOrders: function () {
                return {
                    asc: "По возрастанию",
                    desc: "По убыванию"
                };
            }
        };

        $.extend(locale, translations);

    }($.fn.bootstrapTable.locales["ru"] = $.fn.bootstrapTable.locales["ru"] || {}));

})(jQuery);