/// <reference path="../../../app-common/app-core/app-core.js" />
/// <reference path="../../../app-common/app-core-ajax/app-core-ajax.js" />
/// <reference path="../../../../bower_components/bootstrap-table/dist/bootstrap-table.js" />

(function (ns) {
    "use strict";

    (function (table) {
        
        table.init = function(tableObject, options) {
            var settings = $.extend({}, table.defaults, options, { locale: ns.config.locale });
            var $table = $(tableObject);
            $table.bootstrapTable(settings);

            if (settings.detailView) {
                $table.addClass("bootstrap-table-details-col");
            }
            return $table;
        }

        table.initSelectTable = function(tableObject, options) {
            function clearSelectedRows($table) {
                selectedItem = null;
        
                //var $table = $(tableObject);
                $table.bootstrapTable("uncheckAll");
                $("tr.selected").removeClass("selected");

                $table.each(function () {
                    var toolbar = $(this).data("toolbar");
                    disableControls(toolbar);
                })
            }

            function disableControls(toolbar) {
                var $toolbar = $(toolbar);
                var $switchStateButtons = $toolbar.find("[data-switch-state='true']");
                $switchStateButtons.prop("disabled", true);
            }

            function enableControls(toolbar) {
                var $toolbar = $(toolbar);
                var $switchStateButtons = $toolbar.find("[data-switch-state]");

                $switchStateButtons.each(function () {
                    var switchProp = $(this).data("switch-prop");
                    var switchCondition = selectedItem[switchProp];

                    if (switchCondition === undefined || switchCondition)
                        $switchStateButtons.prop("disabled", false);
                })

            }

            function enableEditControls(toolbar, item) {
                var $toolbar = $(toolbar);
                var $switchStateButtons = $toolbar.find("[data-button-edit]");

                $switchStateButtons.each(function() {
                    var switchProp = $(this).data("switch-prop");
                    var switchCondition = selectedItem[switchProp];

                    if (switchCondition === undefined || switchCondition)
                        $switchStateButtons.prop("disabled", false);
                    if (item.liquidationDate != null)
                        $switchStateButtons.prop("disabled", true);
                })
            }

            function enableUpdateControls(toolbar, item) {
                var $toolbar = $(toolbar);
                var $switchStateButtons = $toolbar.find("[data-button-update]");

                $switchStateButtons.each(function() {
                    var switchProp = $(this).data("switch-prop");
                    var switchCondition = selectedItem[switchProp];

                    if (switchCondition === undefined || switchCondition)
                        $switchStateButtons.prop("disabled", false);
                    if (item.country.country != 'Belarus'  || item.liquidationDate != null)
                        $switchStateButtons.prop("disabled", true);
                })
            }

            var settings = $.extend({}, table.defaults, options, { locale: ns.config.locale });

            var $table = $(tableObject);
            $table.bootstrapTable(settings);

            $table.on("click-row.bs.table", function (e, item, $element) {
                clearSelectedRows($table);

                selectedItem = item;
                $($element).addClass("selected");

                var index = $element.data("index");
                $(this).bootstrapTable("check", index);

                var toolbar = $(this).data("toolbar");
                enableControls(toolbar);
                enableEditControls(toolbar, item);
                enableUpdateControls(toolbar, item);
            });

            $table.on("load-success.bs.table", function () {
                clearSelectedRows($table);
            });

            return $table;
        }

        table.refresh = function (tableObject, url) {
            var $table = $(tableObject);
            if (url) {
                $table.bootstrapTable("refreshOptions", { url: url });
            } else {
                $table.bootstrapTable("refresh");
            }
        }

        table.reset = function (tableObject, url) {
            var $table = $(tableObject);
            var settings = $table.bootstrapTable('getOptions');
            if (url) {
                $table.bootstrapTable("refreshOptions", { url: url, pageNumber: 1 });
            } else {
                $table.bootstrapTable("refresh", { pageNumber: 1 });
            }
            if (settings.detailView) {
                $table.addClass("bootstrap-table-details-col");
            }
        }

        table.addFinishLoadingCallback = function (tableObject, callback) {
            var $table = $(tableObject);
            $table.on("load-success.bs.table", function (e) {
                callback();
            });
            $table.on("load-error.bs.table", function (e) {
                callback();
            });
        }

        table.dateFormatter = function (value) {
            var date = new Date(value);
            return Globalize.dateFormatter()(date);
        }

        table.dateTimeFormatter = function (value) {
            if (!value)
                return "-";

            var date = new Date(value);
            return Globalize.dateFormatter({ skeleton: "yMdHms" })(date);
        }

        table.longTextFormatter = function (value) {
            return '<div class="single-line-text">' + value + '</div>';
        }

        table.htmlTextFormatter = function (value) {
            var escaped = ns.escapeHtml(value);
            return '<div class="single-line-text">' + escaped + '</div>';
        }

        table.noteFormatter = function(value) {
            if (value) {
                return "<span class='note-icon far fa-lg fa-sticky-note' title='" + value + "'></span>";
            }
            return "";
        }

        table.boolFormatter = function(value) {
            if (value) {
                return "<span class='fas fa-check'></span>";
            }
            return "";
        }
        table.trueFalseFormatter = function(value) {
            if (value!=null) {
                if (value)
                    return '<div>' + ns.resources.getResource("true") + '</div>';
                else
                    return '<div>' + ns.resources.getResource("false") + '</div>';
            }
            return "";
        }

        table.paginationResponseHandler = function(data) {
            var res = {                
                total: (data.info && data.info.paging) ? data.info.paging.count : 0,
                rows: data.entities
            };
            return res;
        }

        var selectedItem = null;

        table.getSelectedItem = function (table) {
            return selectedItem;
        }

        table.defaults = {
            toolbarAlign: "left",
            searchAlign: "right",
            showRefresh: true,
            buttonsAlign: "right",
            pagination: true,
            sidePagination: "server",
            silentSort: false,
            pageList: [10, 20, 50,'All'],
            search: false,
            trimOnSearch: true,
            showColumns: true,
            showExport: true,
            exportDataType : 'all',
            exportTypes : ['excel'],
            exportOptions : { ignoreColumn: ['detail', 'buttons', 'button'] },
            method: "post",
            queryParamsType: "normal",
            queryParams: getQueryParams,
            rowAttributes: function(row, index) {
                return { "data-id": row.id, "data-index": index };
            },
            ajax: ajaxRequest,

            icons: {
                detailOpen: "glyphicon glyphicon-chevron-right",
                detailClose: "glyphicon glyphicon-chevron-down",
                paginationSwitchDown: "glyphicon-collapse-down icon-chevron-down",
                paginationSwitchUp: "glyphicon-collapse-up icon-chevron-up",
                refresh: "glyphicon-refresh icon-refresh",
                toggle: "glyphicon-list-alt icon-list-alt",
                columns: "glyphicon-th icon-th",
                sort: 'glyphicon-sort',
                plus: 'glyphicon-plus',
                minus: 'glyphicon-minus'
            }
        };


        function getQueryParams(params) {

            var entries = [];
            if (!this.isMultipleSort) {
                if (params.sortName) {
                    entries.push(formatSortParams(params));
                }
            }
            else {
                for (var i = 0; i < this.sortPriority.length; i++) {
                    entries.push(formatSortParams(this.sortPriority[i]));
                }
            }

            return {
                options: {
                    paging: {
                        page: params.pageNumber,
                        pageSize: params.pageSize
                    },
                    sort: entries
                },
                search: {
                    term: params.searchText
                }
            }

            function formatSortParams(params) {
                return {
                    propertyName: params.sortName,
                    sortDir: params.sortOrder
                }
            }
        }

        function ajaxRequest(params) {
            ns.ajax.disableGlobalAjaxAnimation();
            $.ajax({
                cache: params.cache,
                contentType: params.contentType,
                data: params.data,
                dataType: params.dataType,
                type: params.type,
                url: params.url,
                success: function (res) {
                    params.success(res);
                },
                error: function (res) {
                    params.error(res);
                },
                complete: function () {
                    ns.ajax.enableGlobalAjaxAnimation();
                }
            });
        }

    }(ns.table = ns.table || {}));

}($.app = $.app || {}));