
(function (ns) {
    "use strict";

    ns.addInitFunction(function () {

        // enable validation
        ko.validation.init({
                insertMessages: true,
                errorMessageClass: "field-validation-error",
                decorateInputElement: true,
                errorElementClass: "input-validation-error",
                grouping: {
                    deep: true,
                    live: true,
                    observable: true
                }
            });
    });



}($.app = $.app || {}));