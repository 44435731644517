(function ($) {
    "use strict";

    (function (locale) {

        var translations = {
            formatMultipleSort: function () {
                return "Multiple Sort";
            },
            formatAddLevel: function () {
                return "Add Level";
            },
            formatDeleteLevel: function () {
                return "Delete Level";
            },
            formatColumn: function () {
                return "Column";
            },
            formatOrder: function () {
                return "Order";
            },
            formatSortBy: function () {
                return "Sort by";
            },
            formatThenBy: function () {
                return "Then by";
            },
            formatSort: function () {
                return "Sort";
            },
            formatCancel: function () {
                return "Cancel";
            },
            formatDuplicateAlertTitle: function () {
                return "Duplicate(s) detected!";
            },
            formatDuplicateAlertDescription: function () {
                return "Please remove or change any duplicate column.";
            },
            formatSortOrders: function () {
                return {
                    asc: "Ascending",
                    desc: "Descending"
                };
            }
        };

        $.extend(locale, translations);

    }($.fn.bootstrapTable.locales["en"] = $.fn.bootstrapTable.locales["en"] || {}));

})(jQuery);