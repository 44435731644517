/// <reference path="../app-core/app-core.js" />
/// <reference path="app-bootstrap.js" />


(function (ns) {
    "use strict";

    (function (autocomplete) {

        autocomplete.init = function ($input, options) {

            var $formGroup = $input.closest(".form-group");
            var hasFormGroup = $formGroup.length > 0;

            if (hasFormGroup) {

                injectFunction(options, "search", true, function (event, ui) {
                    ns.ajax.disableGlobalAjaxAnimation();
                    ns.bootstrap.setFormGroup($input, "default", "fa fa-spinner fa-spin fa-1");
                });
                injectFunction(options, "response", false, function (event, ui) {
                    ns.bootstrap.setFormGroup($input, "default");
                    ns.ajax.enableGlobalAjaxAnimation();
                });
                
                if (options.fixed) {
                    if ($input.val()) {
                        setFixedState($input);
                    }

                    injectFunction(options, "select", true, function (event, ui) {
                        setFixedState($input);

                        if (options.hiddenValueName) {
                            setHiddenValue($input, ui.item[options.hiddenValueName]);
                        }
                    });
                }

                injectFunction(options, "select", false, function (event, ui) {
                    $input.focusout();  // trigger element validation
                });
            }
            
            $input.autocomplete(options);
        }
        
        autocomplete.isHiddenValueSet = function ($input) {
            var value = autocomplete.getHiddenValue($input);
            if ($.isNumeric(value)) {
                return +value > 0;
            }
            return !!value;
        }

        autocomplete.getHiddenValue = function($input) {
            var dependentSelector = $input.attr("data-dependent-input");
            var $dependentInput = $(dependentSelector);
            var dependentValue = $dependentInput.val();
            return dependentValue;
        }

        autocomplete.setInput = function ($input, value, hiddenValue) {
            $input.val(value);
            setInput($input, hiddenValue);
        }

        autocomplete.clearInput = function ($input) {
            $input.val("");
            setHiddenValue($input, "");
            ns.bootstrap.setFormGroup($input, "default");
        }

        autocomplete.setFixedState = function ($input) {
            setFixedState($input);
        }


        //#region Manage input

        function setInput($input, hiddenValue) {
            setHiddenValue($input, hiddenValue);
            setFixedState($input);
        }

        function clearInput($input) {
            $input.val("");
            setHiddenValue($input, "");
            ns.bootstrap.setFormGroup($input, "default");

            $input.trigger("clear");
        }

        function setFixedState($input) {
            ns.bootstrap.setFormGroup($input, "readonly", "fa fa-times-circle fa-1");
            ns.bootstrap.setFeedbackClickHandler($input, "Очистить", function () {
                clearInput($input);
            });
        }

        function setHiddenValue($input, hiddenValue) {
            var dependentSelector = $input.attr("data-dependent-input");
            var $dependentInput = $(dependentSelector);
            $dependentInput.val(hiddenValue);
        }
        
        //#endregion

        //#region Helpers
        
        function injectFunction(object, functionName, isAtStart, functionForInject) {
            if (!$.isFunction(functionForInject)) {
                return;
            }
            var oldFunction = object[functionName];
            object[functionName] = function () {
                if (isAtStart) {
                    functionForInject.apply(null, Array.prototype.slice.call(arguments));
                    if ($.isFunction(oldFunction)) {
                        oldFunction.apply(null, Array.prototype.slice.call(arguments));
                    }
                } else {
                    if ($.isFunction(oldFunction)) {
                        oldFunction.apply(null, Array.prototype.slice.call(arguments));
                    }
                    functionForInject.apply(null, Array.prototype.slice.call(arguments));
                }
            }
        }

        //#endregion
        
    }(ns.autocomplete = ns.autocomplete || {}));

}($.app = $.app || {}));
