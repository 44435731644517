/// <reference path="../../app-common/app-core/app-core.js" />
/// <reference path="../../app-common/app-core/app-resources.js" />
/// <reference path="../../app-common/app-core-ajax/app-core-ajax.js" />
/// <reference path="../../app-common/app-core-widgets/app-dialog.js" />
/// <reference path="../../app-common/app-validation/app-validation-remote.js" />
/// <reference path="../app-core/app-config.js" />
/// <reference path="../../../bower_components/knockout/dist/knockout.debug.js" />

(function(ns) {
    "use strict";

    (function(organizations) {

        organizations.OrganizationViewModel = function($dialog, options, successCallback) {
            var self = this;
            self.id = options.model.id;
            self.country = ko.observable(options.model.country);
            self.countryName = ko.observable(options.model.countryName);
                self.address = new ns.organizations.AddressViewModel(options.model.address, self.country, self.countryName, options.findTerritorialUnitUrl);
                self.address.country.valueHasMutated();
            self.organizationForm = ko.observable(options.model.organizationForm).extend({
                required: {
                    params: true,
                    message: ns.resources.getResource("required"),
                    onlyIf: function() {
                        return self.country() === "Belarus";
                    }
                }
            });

          
            self.hasName = ko.observable(options.model.name);
            self.name = ko.observable(options.model.name);
            self.isReadonlyName = ko.pureComputed(function() {
                return self.country() === "Belarus"/* && options.model.organizationForm() !== "Individual"*/ && self.hasName();
            });
            self.name.extend({
                required: {
                    params: true,
                    message: ns.resources.getResource("required"),
                    onlyIf: function() {
                        return !self.isReadonlyName();
                    }
                },
                remoteOrgNameValidationRule: {
                    params: {},
                    onlyIf: function() {
                        return !self.isReadonlyName() && self.organizationForm() !== "Individual" && self.name() != null;
                    }
                }
            });

            self.fullName = ko.observable(options.model.fullName).extend({
                required: {
                    params: true,
                    message: ns.resources.getResource("required"),
                    onlyIf: function() {
                        return !self.isReadonlyName();
                    }
                },
                remoteOrgNameValidationRule: {
                    params: {},
                    onlyIf: function() {
                        return !self.isReadonlyName() && self.organizationForm() !== "Individual" && self.name() != null;
                    }
                }
            });
            self.organizationConclusionType = ko.observable(options.model.organizationConclusionType);
            self.phoneConclusion = ko.observable(options.model.phoneConclusion);
            self.isEConclusion = options.model.isEConclusion;
            self.organizationConclusionType.subscribe(function(value) {
                if (!value) {
                    self.phoneConclusion("");
                    self.isEConclusion = "";
                }
            });;
            self.phone = ko.observable(options.model.phone);

            self.parentFullName = ko.observable();
            self.parentName = ko.observable();
            self.isIndividual = ko.pureComputed(function() {
                return self.country() === "Belarus" && self.organizationForm() === "Individual";
            });

         /*   self.address.country.subscribe(function(value) {
                if (value !== "Belarus") {
                    self.organizationForm(null);
                }
            });*/

            self.editOrganization = function() {
                ns.dialogs.closeDialog($dialog);
                ns.organizations.editOrganization(options.getEditorUrl, self.savedOrganization(), function() { document.location.reload(true) });
            }

            self.organizationForm.subscribe(function(value) {
                if (value === "Individual") {
                    self.unp("");
                }
            });

            self.showOrganizationType = ko.computed(function() {
                if (self.organizationForm() === "LegalEntity" ||
                    self.organizationForm() === "BranchOfficeWithBankAccount" ||
                    self.organizationForm() === "BranchOfficeWithoutBankAccount")
                    return true;
                return false;
            });

            self.showEditOrganization = ko.observable(false);
            self.savedOrganization = ko.observable(0);

            self.unp = ko.observable(options.model.unp).extend({
                required: {
                    params: true,
                    message: ns.resources.getResource("required"),
                    onlyIf: function() {
                        return self.organizationForm() !== "Individual";
                    }
                },
           /*     pattern: {
                    params: /^\d{9}$/,
                    onlyIf: function() {
                        return self.address.country() === "Belarus" && self.organizationForm() !== "Individual";
                    },
                    message: "УНП должен состоять из 9 цифр"
                },*/
                validation: {
                    async: true,
                    validator: function(val, params, callback) {
                        self.showEditOrganization(false);
                        self.savedOrganization(0);
                        $.ajax({
                            url: options.validateUnpUrl,
                            type: "POST",
                            data: {
                                unp: val,
                                organizationForm: self.organizationForm(),
                                country: self.country()
                            },
                            success: function(data) {
                                if (data.savedOrganization) {
                                    self.savedOrganization(data.savedOrganization);
                                    self.showEditOrganization(true);
                                }
                                if (self.organizationForm() == "BranchOfficeWithoutBankAccount") {
                                    self.hasName(false);
                                    self.parentName(data.name);
                                    self.parentFullName(data.fullName);
                                }
                                else {
                                    self.hasName(data.name);
                                    self.name(data.name);
                                    self.fullName(data.fullName);
                                }
                                callback({
                                    isValid: data.isValid,
                                    message: data.errorMessage
                                });
                            }
                        });
                    },
                    onlyIf: function() {
                        return options.model.id == 0 && self.organizationForm() !== "" && self.organizationForm() !== "Individual";
                    }
                }
            });
            if (options.model.id == 0) {
                self.branchInfo = new ns.organizations.BranchViewModel(self, options.getBranchesUrl);
            }
            self.isBranchInfoRequired = ko.pureComputed(function() {
                return self.country() === "Belarus" && self.organizationForm() === "BranchOfficeWithoutBankAccount";
            });
        }
    }(ns.organizations = ns.organizations || {}));
}($.app = $.app || {}));