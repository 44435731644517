/// <reference path="../../app-core/app-core.js" />
/// <reference path="../../../../bower_components/bootstrap-datepicker/dist/js/bootstrap-datepicker.js" />

(function (ns) {
    "use strict";

    ns.setDatePicker = function ($input, startDate, endDate) {
        $input.attr("type", "text")
            .datepicker({
                changeDate: function () {
                    $input.removeClass("input-validation-error").next().removeClass("field-validation-error").addClass("field-validation-valid");
                },
                startDate: new Date(startDate), // Today on server
                endDate: new Date(endDate)
            });
    }

    ns.addInitFunction(function () {

        $.fn.datepicker.defaults.language = ns.config.locale;
        $.fn.datepicker.defaults.autoclose = true;
        $.fn.datepicker.defaults.zIndexOffset = 10999;
    });


}($.app = $.app || {}));