/**
 * Bootstrap Table Russian translation
 * Author: Dunaevsky Maxim <dunmaksim@yandex.ru>
 */
(function ($) {
    "use strict";
    
    (function (locale) {

        var translations = {
            formatLoadingMessage: function () {
                return "Пожалуйста, подождите, идёт загрузка...";
            },
            formatRecordsPerPage: function (pageNumber) {
                return pageNumber + " записей на страницу";
            },
            formatShowingRows: function (pageFrom, pageTo, totalRows) {
                return "Записи с " + pageFrom + " по " + pageTo + " из " + totalRows;
            },
            formatSearch: function () {
                return "Поиск";
            },
            formatNoMatches: function () {
                return "Ничего не найдено";
            },
            formatRefresh: function () {
                return "Обновить";
            },
            formatToggle: function () {
                return "Переключить";
            },
            formatColumns: function () {
                return "Колонки";
            },
            formatClearFilters: function () {
                return "Очистить фильтры";
            }
        };

        $.extend(locale, translations);

    }($.fn.bootstrapTable.locales["ru"] = $.fn.bootstrapTable.locales["ru"] || {}));

})(jQuery);
