/// <reference path="../../app-core/app-config.js" />
/// <reference path="../../app-core-ajax/app-core-ajax.js" />
/// <reference path="../../../../bower_components/knockout/dist/knockout.debug.js" />
/// <reference path="../../../../bower_components/knockout-validation/dist/knockout.validation.js" />

(function (ns) {
    "use strict";

    ns.addInitFunction(function () {

        ko.bindingHandlers.selectedText = {
            init: function (element, valueAccessor) {
                var value = valueAccessor();
                value($("option:selected", element).text());

                $(element).change(function () {
                    value($("option:selected", this).text());
                });
            },
            update: function (element, valueAccessor) {
                var value = ko.utils.unwrapObservable(valueAccessor());
                $("option", element).filter(function (i, el) { return $(el).text() === value; }).prop("selected", "selected");
            }
        };

    });

}($.app = $.app || {}));
