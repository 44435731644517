/// <reference path="../../../app-common/app-core/app-core.js" />
/// <reference path="../../../app-common/app-core-ajax/app-core-ajax.js" />
/// <reference path="../../app-tabs.js" />
/// <reference path="../../../../bower_components/knockout/dist/knockout.debug.js" />

(function(ns) {
    "use strict";

    (function(organizations) {
        organizations.AddressViewModel = function(model, country, countryName, url) {
            var self = this;
            self.country = country;
            self.countryName = countryName;
            self.text = ko.observable(model.text);
            self.belarusAddressType = ko.observable(model.belarusAddressType ? model.belarusAddressType : "Locality").extend({
                required: {
                    params: true,
                    message: ns.resources.getResource("required"),
                    onlyIf: function() {
                        return !self.text() && self.country() === "Belarus";
                    }
                }
            });
            self.house = ko.observable(model.house).extend({
                required: {
                    params: true,
                    message: ns.resources.getResource("required"),
                    onlyIf: function() {
                        return !self.text();
                    }
                }
            });
            self.outOfLocalityAddressNote = ko.observable(model.outOfLocalityAddressNote).extend({
                required: {
                    params: true,
                    message: ns.resources.getResource("required"),
                    onlyIf: function() {
                        return !self.text() && self.country() === "Belarus" && self.belarusAddressType() === "OutOfLocality";
                    }
                }
            });
            self.room = ko.observable(model.room);
            self.street = ko.observable(model.street).extend({
                required: {
                    params: true,
                    message: ns.resources.getResource("required"),
                    onlyIf: function() {
                        return !self.text() && self.belarusAddressType() === "Locality";
                    }
                }
            });
            self.streetType = ko.observable(model.streetType);

            self.belarusTerritorialUnitId = ko.observable(model.belarusTerritorialUnitId);
            self.isTerritorialUnitSet = ko.pureComputed(function() {
                return !!self.belarusTerritorialUnitId();
            });

            self.belarusTerritorialUnitName = ko.observable(model.belarusTerritorialUnitName).extend({
                validation: {
                    validator: function(val) {
                        return self.isTerritorialUnitSet();
                    },
                    message: "Необходимо выбрать территориальную единицу из выпадающего списка!",
                    onlyIf: function() {
                        return !self.text() && self.country() === "Belarus";
                    }
                }
            });

            self.unitSource = function (request, response) {
                request.term = request.term.replace('<', '');
                $.ajax({
                    url: url,
                    data: { term: request.term, addressType: self.belarusAddressType() },
                    type: "GET",
                    success: response
                });
            };
            self.selectTerritorialUnit = function(event, ui) {
                self.belarusTerritorialUnitId(ui.item.id);
            };
            self.clearTerritorialUnit = function() {
                self.belarusTerritorialUnitId(null);
            };

            self.belarusAddressType.subscribe(function(value) {
                self.clearTerritorialUnit();
            });

            self.foreignLocality = ko.observable(model.foreignLocality).extend({
                required: {
                    params: true,
                    message: ns.resources.getResource("required"),
                    onlyIf: function() {
                        return !self.text() &&  self.country() !== "Belarus";
                    }
                }
            });
            self.zipCode = ko.observable(model.zipCode).extend({
                required: {
                    params: true,
                    message: ns.resources.getResource("required"),
                    onlyIf: function() {
                        return !self.text()
                    }

                },
                validation: {
                        validator: function(val) {
                            return val.length == 6;
                        },
                        message: "Поле 'Индекс' включает 6 цифр.",
                        onlyIf: function() {
                            return !self.text() &&  self.country() === "Belarus";
                        }
                    }
                });
        }
    }(ns.organizations = ns.organizations || {}));
}($.app = $.app || {}));
