(function ($) {
    "use strict";

    (function (locale) {

        var translations = {
            formatMultipleSort: function () {
                return "Множнае сартаванне";
            },
            formatAddLevel: function () {
                return "Дадаць узровень";
            },
            formatDeleteLevel: function () {
                return "Выдаліць ўзровень";
            },
            formatColumn: function () {
                return "Слупок";
            },
            formatOrder: function () {
                return "Парадак";
            },
            formatSortBy: function () {
                return "Сартаваць па";
            },
            formatThenBy: function () {
                return "Затым па";
            },
            formatSort: function () {
                return "Сартаваць";
            },
            formatCancel: function () {
                return "Адмена";
            },
            formatDuplicateAlertTitle: function () {
                return "Выяўлены дубляваныя палі!";
            },
            formatDuplicateAlertDescription: function () {
                return "Калі ласка, выдаліце дубляваныя слупкі.";
            },
            formatSortOrders: function () {
                return {
                    asc: "Па ўзрастанні",
                    desc: "Па змяншэнні"
                };
            }
        };

        $.extend(locale, translations);

    }($.fn.bootstrapTable.locales["be"] = $.fn.bootstrapTable.locales["be"] || {}));

})(jQuery);