/// <reference path="../app-core/app-core.js" />
/// <reference path="../../../bower_components/jquery.validation/dist/jquery.validate.js" />
/// <reference path="../../../bower_components/Microsoft.jQuery.Unobtrusive.Validation/jquery.validate.unobtrusive.js" />

(function (ns) {
    "use strict";

    function endsWith(str, suffix) {
        return str.indexOf(suffix, str.length - suffix.length) !== -1;
    }


    ns.addInitFunction(function () {

        $(["requiredif", "requiredifnot", "regularexpressionif", "rangeif"])
            .each(function (index, validationName) {

                $.validator.addMethod(validationName,
                    function (value, element, parameters) {
                        // get the target value
                        var targetValuesString = parameters["targetvalues"];
                        var targetValues = (targetValuesString == null ? [] : targetValuesString.split(","));

                        var dependentPropertyName = parameters["dependentproperty"];
                        // get the actual value of the target control
                        var control = $("[name='" + dependentPropertyName + "']");
                        if (control.length > 0) {
                            var actualValue = "";
                            if (control.first().is(":radio")) {
                                actualValue = control.filter(":checked").val();
                            } else {
                                var controltype = control.attr("type");
                                switch (controltype) {
                                    case "checkbox":
                                        actualValue = control.is(":checked").toString();
                                        break;
                                    case "select":
                                        actualValue = $("option:selected", control).text();
                                        break;
                                    default:
                                        actualValue = control.val();
                                        break;
                                }
                            }

                            // if the condition is true, reuse the existing validator functionality
                            var inverseCondition = endsWith(validationName, "not");

                            for (var i = 0; i < targetValues.length; i++) {
                                var targetValue = targetValues[i];

                                if ((targetValue.toLowerCase() === actualValue.toLowerCase() && !inverseCondition) ||
                                    (targetValue.toLowerCase() !== actualValue.toLowerCase() && inverseCondition)) {

                                    var rule = parameters["rule"];
                                    var ruleparam = parameters["ruleparam"];
                                    return $.validator.methods[rule].call(this, value, element, ruleparam);
                                }
                            }

                        }
                        return true;
                    }
                );

                $.validator.unobtrusive.adapters.add(validationName,
                    ["dependentproperty", "targetvalues", "rule", "ruleparam"],
                    function (options) {
                        var rp = options.params["ruleparam"];
                        options.rules[validationName] = {
                            dependentproperty: options.params["dependentproperty"],
                            targetvalues: options.params["targetvalues"],
                            rule: options.params["rule"]
                        };
                        if (rp) {
                            options.rules[validationName].ruleparam = rp.charAt(0) == "[" ? eval(rp) : rp;
                        }
                        options.messages[validationName] = options.message;
                    });
            });

    });


}($.app = $.app || {}));