/// <reference path="../../../app-common/app-core/app-core.js" />
/// <reference path="../../../app-common/app-core-ajax/app-core-ajax.js" />
/// <reference path="../../../../bower_components/datatables.net/js/jquery.dataTables.js" />


$.fn.serializeObject = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || "");
        } else {
            o[this.name] = this.value || "";
        }
    });
    return o;
};


(function (ns) {
    "use strict";

    (function (datatables) {
        datatables.initTables = function (options) {

            var $table = $(options.tableSelector);
            $table.on({
                "preXhr.dt": function (e, settings, data) {
                    ns.ajax.disableGlobalAjaxAnimation();
                },
                "xhr.dt": function (e, settings, data) {
                    ns.ajax.enableGlobalAjaxAnimation();
                }
            });

            var settings = $.extend(true, {}, datatables.defaults, options.datatableSettings);
            if (options.showDetails) {
                var oldfnRowCallback = settings.fnRowCallback;
                settings.fnRowCallback = function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $(nRow).addClass("row-clickable");
                    if ($.isFunction(oldfnRowCallback)) {
                        oldfnRowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull);
                    }
                }
            }

            var table = $table.DataTable(settings);

            if (options.showDetails) {
                $(options.tableSelector + " tbody").on("click",
                    "tr.row-clickable td:not(:last-child)",
                    function(event) {
                        event.stopPropagation();
                        var $tr = $(this).closest("tr");
                        var row = table.row($tr);

                        var showLabel = $table.data("show-label");
                        var closeLabel = $table.data("close-label");

                        if (row.child.isShown()) {
                            // This row is already open - close it
                            row.child.hide();
                            $tr.removeClass("shown");
                            $tr.find("td:first").attr("title", showLabel);
                        } else {
                            // Open this row
                            row.child(row.data()[options.detailsColumn]).show();
                            $tr.addClass("shown");
                            $tr.find("td:first").attr("title", closeLabel);
                        }
                    });
            }

            if (options.extraFormSelector) {
                var $form = $(options.extraFormSelector);
                $form.submit(function (e) {
                    e.preventDefault();
                    table.ajax.reload();
                });
            }
            return table;
        }

        datatables.defaults = {
            destroy: true,
            sServerMethod: "POST",
            ajax: {
                type: "POST"
            },
            bFilter: false
        };

        datatables.ajaxPaginationFunc = function (d, extraFormSelector) {
            d.options = {
                Paging: {
                    Page: Math.floor(d.start / d.length) + 1,
                    PageSize: d.length
                },
                Sort: {
                    SortEntries: []
                }
            }

            if (d.order.length === 0) {
                if (options.defaultSort)
                    d.options.Sort.SortEntries.push({
                        PropertyName: options.defaultSort.name,
                        SortDir: options.defaultSort.dir
                    });
            } else {
                for (var i = 0; i < d.order.length; i++) {
                    d.options.Sort.SortEntries[i] = {
                        PropertyName: d.columns[d.order[i].column].data,
                        SortDir: d.order[i].dir
                    }
                }
            }

            if (extraFormSelector) {
                var formData = $(options.extraFormSelector).serializeObject();
                $.extend(d, formData);
            }
        }

    }(ns.datatables = ns.datatables || {}));

}($.app = $.app || {}));