///<reference path="app-config.js" />
///<reference path="../app-core-ajax/app-core-ajax.js" />

(function(ns) {
    "use strict";

    ns.cookies = {
        set: function(key, value, days) {
            var cookie = key + '=' + JSON.stringify(ko.toJS(value)) + ';path=/';
            if (days) {
                var expires = new Date();
                expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
                cookie += ';expires=' + expires.toUTCString();
            }
            document.cookie = cookie;
        },
        get: function(key) {
            var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
            return keyValue ? JSON.parse(keyValue[2]) : null;
        }
    };
}($.app = $.app || {}));
