/// <reference path="../app-core/app-core.js" />

(function (ns) {
    "use strict";

    (function (validation) {

        validation.setValidationError = function (name, message) {
            var $element = $("[name='" + name + "']");
            if ($element.length === 0) {
                return false;
            }

            var validator = $element.closest("form").validate();
            var object = {};
            object[name] = message;
            validator.showErrors(object);
            return true;
        }

    }(ns.validation = ns.validation || {}));

}($.app = $.app || {}));
