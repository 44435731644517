/// <reference path="../../app-common/app-core/app-core.js" />
/// <reference path="../../app-common/app-core-widgets/app-dialog.js" />
/// <reference path="../../../bower_components/knockout/dist/knockout.debug.js" />
/// <reference path="../../../bower_components/knockout-validation/dist/knockout.validation.js" />

(function (ns) {
    "use strict";

    (function (nsSpecific) {
        nsSpecific.VehicleViewModel = function (vehicle, options) {

            var vehicleViewModel = function () {
                var self = this;

                self.id = ko.observable(vehicle.id);
                self.isNoRegistrationCode = ko.observable(vehicle.isNoRegistrationCode);
                self.numberPlate = ko.observable(vehicle.numberPlate);
                self.numberPlate.subscribe(function(val) {
                    if (val != null && $.trim(val) != "")
                        self.isNoRegistrationCode(false);
                    else {
                        self.isNoRegistrationCode(true);
                    }
                });
                self.type = ko.observable(vehicle.type);
                self.brand = ko.observable(vehicle.brand);
                self.mass = ko.observable(vehicle.mass);
                self.model = ko.observable(vehicle.model);
                self.numberOfAxles = ko.observable(vehicle.numberOfAxles);
                self.permissibleWeight = ko.observable(vehicle.permissibleWeight);
                self.owner = ko.observable({
                    id: vehicle.owner ? vehicle.owner.id : null,
                    name: vehicle.owner ? vehicle.owner.name : null
                });
                self.features = ko.observableArray(ko.utils.arrayMap(vehicle.features,
                    function (feature) {
                        return {
                            isSet: feature.isSet,
                            name: ns.getValueByKey(options.featureTypeKeyValueCollection, feature.type),
                            type: feature.type
                        };
                    }));

                self.vehicleTypeText = ko.pureComputed(function () {
                    return ns.getValueByKey(options.vehicleTypeKeyValueCollection, self.type());
                });
                self.numberPlateText = ko.pureComputed(function () {
                    return self.isNoRegistrationCode()
                        ? ns.resources.getResource("noNumber")
                        : self.numberPlate();
                });
            }

            return new vehicleViewModel();
        };
    }(ns.viewModels = ns.viewModels || {}));

}($.app = $.app || {}));
