///<reference path="app-config.js" />
///<reference path="../app-core-ajax/app-core-ajax.js" />

(function (ns) {
    "use strict";

    var resources = null;
    
    ns.resources = {
        
        getResource: function (key) {

            if (!resources) {
                console.error("resources not defined");
                return "[X]";
            }

            var value = resources[key];
            if (!value) {
                console.error("app-resources.js: resource with key '" + key + "' not found");
                return "[X]";
            }
            
            return value;
        }
    };
    
    ns.addInitFunction(function () {

        var key = "resources-" + ns.config.appName + "-" + ns.config.locale;
        var resourcesJson = sessionStorage.getItem(key);

        if (resourcesJson) {
            resources = JSON.parse(resourcesJson);
        } else {
            $.ajax({
                url: ns.config.localizedResourcesUrl,
                method: "GET",
                async: false,
                success: function(data) {
                    sessionStorage.setItem(key, JSON.stringify(data));
                    resources = data;
                }
            });
        }
    });


}($.app = $.app || {}));
