/// <reference path="../app-core/app-core.js" />
/// <reference path="app-globalize-resources.js" />


(function (ns) {
    "use strict";

    ns.addInitFunction(function () {

        Globalize.load(ns.globalize.likelySubtags);
        Globalize.load(ns.globalize.cldrJsonData);
        Globalize.locale(ns.config.locale);

    });



}($.app = $.app || {}));