/// <reference path="../app-core/app-core.js" />
/// <reference path="../../../bower_components/remarkable-bootstrap-notify/dist/bootstrap-notify.js" />



(function (ns) {
    "use strict";

    function initUpButton() {

        $('.btn-go-top').click(function() {
            $('body').scrollTop(0);
            $('body').parent().scrollTop(0);
        });

        window.onscroll = function() {
            if ($('body').scrollTop() > 120 || $('body').parent().scrollTop() > 120) {
                $(".btn-go-top").show();
            } else {
                $(".btn-go-top").hide();
            }
        };

    };
    initUpButton();

    function createMessage(msg) {
        var icon = "glyphicon glyphicon-";
        msg.type = msg.type.toLowerCase();
        switch (msg.type) {
            case "success":
                icon += "ok";
                break;
            case "info":
                icon += "info-sign";
                break;
            case "warning":
            case "danger":
                icon += "warning-sign";
                break;
            default:
                icon += "info-sign";
                break;
        }

        var options = {
            icon: icon,
            title: msg.title,
            message: msg.content.replace(new RegExp("\r?\n", "g"), "<br />"),
            url: msg.url,
            target: "_blank"
        };

        var settings = {
            element: "body",
            type: msg.type,
            placement: msg.placement,
            delay: msg.delay,
            animate: {
                enter: "animated fadeInDown",
                exit: "animated fadeOutUp"
            },
            onClose: msg.onClose,
            z_index: 9999999,
            template:
                '<div data-notify="container" role="alert" class="col-xs-22 col-sm-7 alert alert-{0}">\
                <button type="button" class="close" data-notify="dismiss" style="top:7px;">\
                    <span aria-hidden="true">×</span>\
                    <span class="sr-only">Close</span>\
                </button>\
                <span data-notify="icon"></span>\
                <span data-notify="title">{1}</span>\
                <span data-notify="message">{2}</span>\
                <a href="{3}" target="{4}" data-notify="url"></a>\
            </div>'
        };

        return $.notify(options, settings);
    }

    function showMessages(data) {
        if ($.isArray(data)) {
            $("[data-notify]").remove();
            for (var i = 0; i < data.length; i++) {
                createMessage(data[i]);
            };
        }
    }


    (function (messages) {

        messages.showMessage = function (msg) {
            createMessage(msg);
        }

        messages.showMessages = function (data) {
            showMessages(data);
        };

        messages.loadMessages = function () {
            $.ajax({
                url: ns.config.getNewMessagesUrl,
                cache: false,
                type: "GET",
                dataType: "json",
                success: function (data) {
                    showMessages(data);
                }
            });
        };

        messages.showErrorMessage = function (errorMsg) {   
            var msg = {
                type: "danger",
                content: errorMsg || "Произошла ошибка. Обновите страницу (данные на странице могут быть потеряны) и попробуйте повторить действие ещё раз.",
                delay: 0
            };
            createMessage(msg);
        };

    }(ns.messages = ns.messages || {}));


    ns.addInitFunction(function () {

        $(document).on("partialHtmlRender", function () {
            $.notifyClose();
        });

    });

}($.app = $.app || {}));
