!function(a) {
    a.fn.datepicker.dates.be = {
        days: ['нядзеля', 'панядзелак', 'аўторак', 'серада', 'чацьвер', 'пятніца', 'субота'],
        daysShort: ['ндз', 'пнд', 'аўт', 'срд', 'чцв', 'птн', 'сбт'],
        daysMin: ['Нд', 'Пн', 'Аў', 'Ср', 'Чц', 'Пт', 'Сб'],
        months: [
            'Студзень', 'Люты', 'Сакавік', 'Красавік', 'Травень', 'Чэрвень', 'Ліпень', 'Жнівень', 'Верасень',
            'Кастрычнік', 'Лістапад', 'Сьнежань'
        ],
        monthsShort: ['Сту', 'Лют', 'Сак', 'Кра', 'Тра', 'Чэр', 'Ліп', 'Жні', 'Вер', 'Кас', 'Ліс', 'Сьн'],
        today: "Сёньня",
        clear: "Ачысціць",
        format: "dd.mm.yyyy",
        weekStart: 1
    }
}(jQuery);