/// <reference path="../../../../app-common/app-core/app-core.js" />
/// <reference path="../../../../app-common/app-core-ajax/app-core-ajax.js" />
/// <reference path="../../../../app-common/app-core-widgets/app-bootstrap-table/app-bootstrap-table.js" />
/// <reference path="../../../app-tabs.js" />
/// <reference path="../../../app-request/app-request-wizard.js" />
/// <reference path="../../../../../bower_components/alertifyjs/dist/js/alertify.js" />

(function(ns) {
    "use strict";

    (function(payment) {
        payment.init_PaymentList = function(viewData) {

            var filterViewModel = ko.dataFor($("#search-filter")[0]);

            var tableOptions = {
                toolbar: "[data-table-toolbar]",
                pageSize: 20,    
                responseHandler: ns.table.paginationResponseHandler,
                detailView: true,
                detailFormatter: function(index, row, $element) {
                    if (!row.hasRequestPayments)
                        return;
                    ns.ajax.disableGlobalAjaxAnimation();
                    $.ajax({
                        url: viewData.extraInfoUrl,
                        method: "POST",
                        data: { paymentId: row.paymentId },
                        success: function(data) {
                            $element.html(data);
                            ns.ajax.enableGlobalAjaxAnimation();
                        }
                    });
                    return "<span class='spinnered'></span>";
                },
                queryParams: function(params) {
                    var queryObj = ns.table.defaults.queryParams(params);
                    queryObj.filter = filterViewModel.currentFilterState();
                    return queryObj;
                },
                onClickRow: function(row, $tr, field) {
                    if (!row.hasRequestPayments)
                        return false;
                    if (field === "buttons")
                        return;

                    if ($tr.next().is("tr.detail-view")) {
                        $table.bootstrapTable("collapseRow", $tr.data("index"));
                    } else {
                        $table.bootstrapTable("expandRow", $tr.data("index"));
                    }

                },
                rowStyle: function() {
                    return {
                        css: { "cursor": "pointer" }
                    };
                },
                columns: [
                    {
                        field: "buttons",
                        formatter: function(value, row, index, field) {
                            if (row.hasRequestPayments)
                                return "";
                            else
                                return viewData.buttonsHtml;
                        },

                        sortable: false,
                        events: {
                            "click .delete-btn": function(e, value, row, index) {
                                bootbox.confirm({
                                    title: "Удаление платежного документа",
                                    message: "Вы действительно хотите удалить платежный документ?",
                                    buttons: {
                                        confirm: {
                                            label: ns.resources.getResource("ok"),
                                            className: "btn-success"
                                        },
                                        cancel: {
                                            label: ns.resources.getResource("cancel"),
                                            className: "btn-default"
                                        }
                                    },
                                    callback: function(result) {
                                        if (result) {
                                            $.ajax({
                                                url: viewData.deleteUrl,
                                                method: "POST",
                                                data: { paymentId: row.paymentId },
                                                success: function(data) {
                                                    ns.messages.loadMessages();
                                                    if (data.success) {
                                                        ns.table.refresh($table);
                                                    };
                                                }
                                            });
                                        }
                                    }
                                });

                            },
                            "click .edit-btn": function(e, value, row, index) {
                                ns.payment.PaymentEdit(viewData.getEditorUrl, row.paymentId, function() { ns.table.refresh($table); });
                            },
                        }
                    }, {}, {}, {}, {}, {}, {},
                    {
                        field: "sum",
                        formatter: function(value, row, index) {
                            return value + " " + row.currency;
                        }
                    },

                    {
                        field: "balance",
                        formatter: function(value, row, index) {
                            return value ? value + " " + row.currency : 0 + " " + row.currency;
                        }
                    },
                ]
            };
            
            $("#add-payment").click(function(e) {
                var url = $(this).data("url");
                ns.payment.PaymentEdit(url, null, function(payment) { ns.table.refresh($table); });
            });
            var $table = ns.table.init("[data-table]", tableOptions);
            $table.on('load-success.bs.table', function(e,data) {
                $table.bootstrapTable('hideColumn', 'buttons');               
                for (var i = 0; i < data.rows.length; i++) {
                    if (!data.rows[i].hasRequestPayments)
                        $table.bootstrapTable('showColumn', 'buttons');
                }
            });
            filterViewModel.currentFilterState.subscribe(function(value) {
                ns.table.refresh($table);
            });
        };

    }(ns.payment = ns.payment || {}));

}($.app = $.app || {}));