/// <reference path="../../../app-common/app-core/app-core.js" />
/// <reference path="../../../../bower_components/datatables.net/js/jquery.dataTables.js" />

(function (ns) {
    "use strict";

    var translations = {
        'ru': {
            "processing": "Подождите...",
            "search": "Поиск:",
            "lengthMenu": "Показать _MENU_ записей",
            "info": "Записи с _START_ до _END_ из _TOTAL_ записей",
            "infoEmpty": "Записи с 0 до 0 из 0 записей",
            "infoFiltered": "(отфильтровано из _MAX_ записей)",
            "infoPostFix": "",
            "loadingRecords": "Загрузка записей...",
            "zeroRecords": "Записи отсутствуют.",
            "emptyTable": "В таблице отсутствуют данные",
            "paginate": {
                "first": "Первая",
                "previous": "Предыдущая",
                "next": "Следующая",
                "last": "Последняя"
            },
            "aria": {
                "sortAscending": ": активировать для сортировки столбца по возрастанию",
                "sortDescending": ": активировать для сортировки столбца по убыванию"
            }
        },
        'en': {
            "sEmptyTable": "No data available in table",
            "sInfo": "Showing _START_ to _END_ of _TOTAL_ entries",
            "sInfoEmpty": "Showing 0 to 0 of 0 entries",
            "sInfoFiltered": "(filtered from _MAX_ total entries)",
            "sInfoPostFix": "",
            "sInfoThousands": ",",
            "sLengthMenu": "Show _MENU_ entries",
            "sLoadingRecords": "Loading...",
            "sProcessing": "Processing...",
            "sSearch": "Search:",
            "sZeroRecords": "No matching records found",
            "oPaginate": {
                "sFirst": "First",
                "sLast": "Last",
                "sNext": "Next",
                "sPrevious": "Previous"
            },
            "oAria": {
                "sSortAscending": ": activate to sort column ascending",
                "sSortDescending": ": activate to sort column descending"
            }
        },
        'be': {
            "sEmptyTable": "У табліцы адсутнічаюць дадзеныя",
            "sProcessing": "Пачакайце...",
            "sLengthMenu": "Паказваць _MENU_ запісаў",
            "sZeroRecords": "Запісы адсутнічаюць.",
            "sInfo": "Запісы з _START_ па _END_ з _TOTAL_ запісаў",
            "sInfoEmpty": "Запісы з 0 па 0 з 0 запісаў",
            "sInfoFiltered": "(адфільтравана з _MAX_ запісаў)",
            "sInfoPostFix": "",
            "sSearch": "Пошук:",
            "sUrl": "",
            "oPaginate": {
                "sFirst": "Першая",
                "sPrevious": "Папярэдняя",
                "sNext": "Наступная",
                "sLast": "Апошняя"
            },
            "oAria": {
                "sSortAscending": ": актываваць для сартавання слупка па ўзрастанні",
                "sSortDescending": ": актываваць для сартавання слупка па змяншэнні"
            }
        }
    }

    ns.addInitFunction(function () {

        $.extend(true, $.fn.dataTable.defaults, {
            "language": translations[ns.config.locale],
            "order": [],

            dom:
                "<'row'<'col-sm-12'l><'col-sm-12'f>>" +
                "<'row'<'col-sm-24'tr>>" +
                "<'row'<'col-sm-10'i><'col-sm-14'p>>",
            renderer: "bootstrap"
        });
        
        $(document).on("shown.bs.modal", function (e) {
            $.fn.dataTable.tables({ visible: true, api: true }).columns.adjust().draw();
        });

        $(document).on("shown.bs.tab", "a[data-toggle=\"tab\"]", function (e) {
            $.fn.dataTable.tables({ visible: true, api: true }).columns.adjust().draw();
        });
    });


}($.app = $.app || {}));