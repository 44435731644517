/// <reference path="../app-core/app-core.js" />

(function (ns) {
    "use strict";

    ns.bootstrap = {};

    ns.bootstrap.setFormGroup = function ($input, type, feedbackClass) {
        var $formGroup = $input.closest(".form-group");
        if ($formGroup.length === 0) {
            return;
        }
        $input.prop("disabled", false);
        $input.prop("readonly", false);

        var classList = $formGroup.prop("classList");
        for (var i = 0; i < classList.length; i++) {
            var item = classList[i];
            if (item.indexOf("has-") !== -1) {
                $formGroup.removeClass(item);
            }
        }
        $formGroup.addClass("form-group");

        switch (type) {
            case "default":
                break;

            case "readonly":
                $input.prop("readonly", true);
                break;

            case "disabled":
                $input.prop("disabled", true);
                break;

            case "success":
                $formGroup.addClass("has-success");
                break;

            case "warning":
                $formGroup.addClass("has-warning");
                break;

            case "error":
                $formGroup.addClass("has-error");
                break;

            default:
                break;
        }

        if (feedbackClass) {
            setFeedback($input, $formGroup, feedbackClass);
        } else {
            removeFeedback($input, $formGroup);
        }
        
    }

    ns.bootstrap.setFeedbackClickHandler = function ($input, title, handler) {
        var $formGroup = $input.closest(".form-group.has-feedback");
        if ($formGroup.length === 0) {
            return;
        }
        var $feedback = $formGroup.find(".form-control-feedback");
        $feedback.css("cursor", "pointer");
        $feedback.css("pointer-events", "auto");
        $feedback.attr("title", title);

        if ($.isFunction(handler)) {
            $feedback.click(handler);
        }
    }

    ns.bootstrap.setAlert = function($block, alertClass, iconClass) {
        $block.removeClass();
        $block.addClass(alertClass);

        var $iconElement = $block.find("span.glyphicon");
        var hasIcon = $iconElement.length > 0;

        if (iconClass) {
            if (hasIcon) {
                $iconElement.removeClass();
                $iconElement.addClass(iconClass);
            } else {
                $block.prepend("<span class='" + iconClass + "' aria-hidden=\"true\"></span>");
            }
        } else {
            $iconElement.remove();
        }
    }



    function removeFeedback($input, $formGroup) {
        $formGroup.removeClass("has-feedback");
        var $feedback = $input.next(".form-control-feedback");
        var $feedbackNext = $input.next().next(".form-control-feedback");

        if ($feedback.length > 0) {
            $feedback.remove();
        }
        else if ($feedbackNext.length > 0) {
            $feedbackNext.remove();
        }
    }

    function setFeedback($input, $formGroup, feedbackClass) {
        $formGroup.addClass("has-feedback");
        var $feedback = $input.next(".form-control-feedback");
        if ($feedback.length > 0) {
            $feedback.removeClass();
            $feedback.addClass("form-control-feedback " + feedbackClass);
        } else {
            $input.after("<span class=\"form-control-feedback " + feedbackClass + " \"></span>");
        }
    }


    ns.addInitFunction(function() {

        fixLabelsPadding();
        $(document).on("partialHtmlRender", function() {
            fixLabelsPadding();
        });

        function fixLabelsPadding() {
            $("label.control-label").each(function (index, element) {
                var $element = $(element);
                var lineCount = getLineCount($element);
                if (lineCount > 1) {
                    $element.css("padding-top", "0");
                }
            });
        }

        function getLineCount($element) {
            var height = $element.height();
            var lineHeight = parseFloat($element.css("line-height"));
            var lineCount = height / lineHeight;
            return Math.round(lineCount);
        }
    });


}($.app = $.app || {}));