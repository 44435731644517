/**
 * Bootstrap Table English translation
 * Author: Zhixin Wen<wenzhixin2010@gmail.com>
 */
(function ($) {
    "use strict";

    (function (locale) {

        var translations = {
            formatLoadingMessage: function () {
                return "Loading, please wait...";
            },
            formatRecordsPerPage: function (pageNumber) {
                return pageNumber + " rows per page";
            },
            formatShowingRows: function (pageFrom, pageTo, totalRows) {
                return "Showing " + pageFrom + " to " + pageTo + " of " + totalRows + " rows";
            },
            formatSearch: function () {
                return "Search";
            },
            formatNoMatches: function () {
                return "No matching records found";
            },
            formatPaginationSwitch: function () {
                return "Hide/Show pagination";
            },
            formatRefresh: function () {
                return "Refresh";
            },
            formatToggle: function () {
                return "Toggle";
            },
            formatColumns: function () {
                return "Columns";
            },
            formatAllRows: function () {
                return "All";
            },
            formatExport: function () {
                return "Export data";
            },
            formatClearFilters: function () {
                return "Clear filters";
            }
        };

        $.extend(locale, translations);

    }($.fn.bootstrapTable.locales["en"] = $.fn.bootstrapTable.locales["en"] || {}));
    
})(jQuery);
