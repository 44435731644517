/// <reference path="../../../bower_components/jquery/dist/jquery.js" />
/// <reference path="../../../bower_components/jquery-validation/dist/jquery.validate.js" />
/// <reference path="../../../bower_components/Microsoft.jQuery.Unobtrusive.Validation/jquery.validate.unobtrusive.js" />
/// <reference path="../../../bower_components/globalize/dist/globalize.js" />
/// <reference path="../../../bower_components/globalize/dist/globalize/number.js" />


(function (ns) {
    "use strict";

    ns.config = {
        isTZset: "",

        locale: "ru",

        getNewMessagesUrl: "",

        localizedResourcesUrl: "",

        maxDecimalPlaces: 3,

        decimalSeparators: [".", ","],

        appName: ""
    };
    

}($.app = $.app || {}));