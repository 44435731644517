///<reference path="app-config.js" />
///<reference path="../app-core-ajax/app-core-ajax.js" />

(function(ns) {
    "use strict";
    ns.addInitFunction(function() { 
        (function setTimezoneOffset() {
            var tzKey = "timezoneOffset";
            //if (!ns.cookies.get(tzKey)) {
                var tzValue = - new Date().getTimezoneOffset() / 60;
                ns.cookies.set(tzKey, tzValue);
         //   }
        }());
    });
}($.app = $.app || {}));
