/// <reference path="../app-core/app-core.js" />

(function (ns) {
    "use strict";

    (function (feedbackDialog) {

        feedbackDialog.create = function (message, type, options) {
            switch (type) {
                case "Alert":
                    alertify.alert(message);
                    break;
                case "Confirm":
                    alertify.okBtn(options.okBtn)
                        .cancelBtn(options.cancelBtn)
                        .confirm(message, options.onOkay, options.onCancel);
                    break;
            }
        };

    }(ns.feedbackDialog = ns.feedbackDialog || {}));

}($.app = $.app || {}));
