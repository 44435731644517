/// <reference path="../../app-core/app-config.js" />
/// <reference path="../../app-core-ajax/app-core-ajax.js" />
/// <reference path="../../../../bower_components/knockout/dist/knockout.debug.js" />
/// <reference path="../../../../bower_components/knockout-validation/dist/knockout.validation.js" />

(function (ns) {
    "use strict";

    ns.addInitFunction(function () {
        ko.bindingHandlers.datepicker = {
            init: function(element, valueAccessor, allBindingsAccessor) {
                var options = allBindingsAccessor().datepickerOptions || {};
                $(element).datepicker(options);

                $(element).on("changeDate clearDate hide", function (ev) {
                    var observable = valueAccessor();
                    observable(ev.date);
                    $(element).blur();
                });

                var value = ko.utils.unwrapObservable(valueAccessor());
                $(element).datepicker("update", value ? new Date(value) : null);
            },
            update: function(element, valueAccessor) {
                var value = ko.utils.unwrapObservable(valueAccessor());
                $(element).datepicker("setDate", value);
            }
        };

        ko.validation.makeBindingHandlerValidatable("datepicker");
    });

}($.app = $.app || {}));
