/// <reference path="../../app-core/app-config.js" />
/// <reference path="../../app-core-ajax/app-core-ajax.js" />
/// <reference path="../../../../bower_components/knockout/dist/knockout.debug.js" />
/// <reference path="../../../../bower_components/knockout-validation/dist/knockout.validation.js" />

(function (ns) {
    "use strict";

    ns.addInitFunction(function () {

        ko.bindingHandlers.ko_autocomplete = {
            init: function (element, valueAccessor) {
                var $element = $(element);
                var options = valueAccessor();
                if (options.dependentState) {
                    options.fixed = true;
                }

                ns.autocomplete.init($element, options);
                if ($.isFunction(options.clear)) {
                    $element.on("clear", function () {
                        options.clear();
                    });
                }
            },
            update: function (element, params) {

                var options = params();
                $(element).autocomplete("option", "source", options.source);

                if (options.dependentState) {
                    var state = options.dependentState();
                    if (state) {
                        ns.autocomplete.setFixedState($(element));
                    } else {
                        ns.autocomplete.clearInput($(element));
                    }
                }
            }
        };

    });

}($.app = $.app || {}));
