///<reference path="app-config.js" />

(function (ns) {
    "use strict";

    var keyValueCollections = [];
    var dictionaries = [];
    
    function addNewCollection(keyValueCollection) {
        var length = keyValueCollections.push(keyValueCollection);
        var index = length - 1;

        dictionaries[index] = {};
        keyValueCollection.forEach(function(item, i) {
            dictionaries[index][item.key] = item.value;
        });
        return index;
    }
    
    ns.getValueByKey = function (keyValueCollection, key) {

        var index = keyValueCollections.indexOf(keyValueCollection);
        if (index === -1) {
            index = addNewCollection(keyValueCollection);
        }
        var dictionary = dictionaries[index];
        return dictionary[key];
    }

}($.app = $.app || {}));
