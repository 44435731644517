///<reference path="app-config.js" />

(function (ns) {
    "use strict";
    
    ns.getObjectProperty = function(obj, path) {
        path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
        path = path.replace(/^\./, "");           // strip a leading dot
        var a = path.split(".");
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in obj) {
                obj = obj[k];
            } else {
                return null;
            }
        }
        return obj;
    }

    ns.setObjectProperty = function(obj, path, value) {
        path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
        path = path.replace(/^\./, "");           // strip a leading dot

        assign(obj, path, value);

        function assign(obj, path, value) {
            if (typeof path === "string")
                path = path.split(".");

            if (path.length > 1) {
                var elementName = path.shift();
                if (elementName) {
                    if (!obj[elementName]) {
                        var isArray = path[0] && /^\d+$/.test(path[0]);
                        obj[elementName] = isArray ? [] : {};
                    }
                    assign(obj[elementName], path, value);
                }
            } else
                obj[path[0]] = value;
        }
    }

    ns.deepIterate = function(object, callback) {

        iterate(object, "");

        function iterate(obj, stack) {
            for (var propertyName in obj) {
                if (obj.hasOwnProperty(propertyName)) {

                    var propertyPath = getPropertyPath(stack, propertyName, obj);
                    var propertyValue = obj[propertyName];

                    if (typeof propertyValue == "object") {
                        iterate(propertyValue, propertyPath);
                    } else {
                        if (callback) {
                            callback(propertyPath, propertyValue);
                        }
                    }
                }
            }
        }
        function getPropertyPath(stack, propertyName, object) {
            if ($.isArray(object)) {
                return stack + "[" + propertyName + "]";
            }
            if (stack) {
                return stack + "." + propertyName;
            }
            return propertyName;
        }
    }

    ns.getFileExtension = function (filename) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }

    ns.arrayIncludes = function(array, item) {
        return array.indexOf(item) > -1;
    }

    ns.escapeHtml = function (text) {
        var entityMap = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#39;',
            '/': '&#x2F;',
            '`': '&#x60;',
            '=': '&#x3D;'
        };

        return String(text).replace(/[&<>"'`=\/]/g, function (s) {
            return entityMap[s];
        });
    }

}($.app = $.app || {}));
