/// <reference path="../../../app-common/app-core/app-core.js" />
/// <reference path="../../../app-common/app-core-ajax/app-core-ajax.js" />
/// <reference path="../../app-tabs.js" />
/// <reference path="../../../../bower_components/knockout/dist/knockout.debug.js" />

(function(ns) {
    "use strict";

    (function(organizations) {
        organizations.editOrganization = function(url, id, successCallback) {
            $.ajax({
                url: url,
                method: "POST",
                data: { id: id },
                success: function(data) {
                    initModal(data, successCallback);
                }
            });

            function ViewModel($dialog, options, successCallback) {
                self = this;

                self.dataModel = ko.validatedObservable(new ns.organizations.OrganizationViewModel($dialog, options, successCallback));
                self.errors = ko.validation.group(self, { deep: true });
                self.save = function() {
                    if (!self.dataModel.isValid()) {
                        console.log("model not valid", self.errors());
                        self.errors.showAllMessages(true);
                        return false;
                    }
                    $.ajax({
                        url: options.saveOrganizationUrl,
                        contentType: "application/json",
                        type: "POST",
                        data: ko.toJSON(self.dataModel),
                        success: function(data) {
                            if (data.isValid) {
                                successCallback(data.org);
                                ns.dialogs.closeDialog($dialog);
                            }
                            ns.messages.loadMessages();
                        }
                    });

                };
            }



            function initModal(data, successCallback) {
/*
                var $dialog = ns.dialogs.openDialog({
                    message: data,
                    size: "large",
                    title: ns.resources.getResource("editOrganization"),                   
                });
                */
                
                var $dialog = ns.dialogs.openDialog({
                    message: data,
                    size: "large",
                    title: ns.resources.getResource("editOrganization"),
                    buttons: {
                        save: {
                            label: ns.resources.getResource("save"),
                            className: "btn-primary btn-save",
                            callback: function() {
                                return false;
                            }
                        }
                    }
                });

                $dialog.find(".btn-save").attr("data-bind", "click: save");
                var options = JSON.parse($dialog.find("#options").val());
                var viewModel = new ViewModel($dialog, options, successCallback);
                if (!options.canEdit) {
                    $dialog.find(".btn-save").remove();
                    $dialog.find('fieldset').attr("disabled", "disabled");
                }
                ko.applyBindings(viewModel, $dialog[0]);
            }
        };

    }(ns.organizations = ns.organizations || {}));

}($.app = $.app || {}));
