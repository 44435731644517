/// <reference path="../app-core/app-core.js" />
/// <reference path="../../../bower_components/jquery.validation/dist/jquery.validate.js" />

(function (ns) {
    "use strict";

    ns.addInitFunction(function () {
        
        // Валидация чисел и диапазонов чисел с учётом как точки, так и запятой в качестве десятичного разделителя
        $.validator.methods.range = function (value, element, param) {
            var globalizedValue = value.replace(",", ".");
            return this.optional(element) || (globalizedValue >= param[0] && globalizedValue <= param[1]);
        }
        $.validator.methods.number = function (value, element) {
            return this.optional(element) || /^-?(?:\d+|\d{1,3}(?:[\s\.,]\d{3})+)(?:[\.,]\d+)?$/.test(value);
        };

        // Валидация даты с учётом Globalize
        $.validator.methods.date = function (value, element) {
            var opt = this.optional(element);
            var gl = Globalize.parseDate(value) !== null;
            return this.optional(element) || Globalize.parseDate(value) !== null;
        }

        // В отличие от стандартного поведения валидатора валидировать также readonly-поля
        $.validator.prototype.elements = function () {
            var validator = this,
                rulesCache = {};

            return $(this.currentForm)
                .find("input, select, textarea")
                .not(":submit, :reset, :image, [disabled]") // changed from: .not( ":submit, :reset, :image, [disabled], [readonly]" )
                .not(this.settings.ignore)
                .filter(function () {
                    if (!this.name && validator.settings.debug && window.console) {
                        console.error("%o has no name assigned", this);
                    }

                    if (this.name in rulesCache || !validator.objectLength($(this).rules())) {
                        return false;
                    }

                    rulesCache[this.name] = true;
                    return true;
                });
        };

    });
    


}($.app = $.app || {}));