/// <reference path="../../app-core/app-config.js" />
/// <reference path="../../app-core-ajax/app-core-ajax.js" />
/// <reference path="../../../../bower_components/knockout/dist/knockout.debug.js" />

(function (ns) {
    "use strict";

    ns.addInitFunction(function () {

        ko.extenders.async = function (computedDeferred, initialValue) {

            var plainObservable = ko.observable(initialValue), currentDeferred;
            plainObservable.inProgress = ko.observable(false);

            ko.computed(function () {
                if (currentDeferred) {
                    currentDeferred.reject();
                    currentDeferred = null;
                }

                var newDeferred = computedDeferred();
                if (newDeferred &&
                    (typeof newDeferred.done == "function")) {

                    // It's a deferred
                    plainObservable.inProgress(true);

                    // Create our own wrapper so we can reject
                    currentDeferred = $.Deferred().done(function (data) {
                        plainObservable.inProgress(false);
                        plainObservable(data);
                    });
                    newDeferred.done(currentDeferred.resolve);
                } else {
                    // A real value, so just publish it immediately
                    plainObservable(newDeferred);
                }
            });

            return plainObservable;
        };

    });


}($.app = $.app || {}));
