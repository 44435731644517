/// <reference path="../../app-common/app-core/app-core.js" />
/// <reference path="../../app-common/app-core-ajax/app-core-ajax.js" />

(function (ns) {
    "use strict";

    ns.document = {};

    ns.document.create = function (url, openAfter) {
        if (url) {
            ns.ajax.disableGlobalAjaxAnimation();
            $.post(url, null, function (responseData) {
                if (responseData.message) {
                    ns.messages.showMessage({ content: responseData.message, type: "info" });
                }
                if (openAfter) {
                    window.open(responseData.documentDownloadUrl, "_blank");
                }
                ns.ajax.enableGlobalAjaxAnimation();
            });
        }
    };

}($.app = $.app || {}));