/// <reference path="../app-core/app-core.js" />
/// <reference path="../../../bower_components/bootbox.js/bootbox.js" />

(function (ns) {
    "use strict";

    var defaults = {
        title: " ",
        onEscape: function () { },
        show: true,
        closeButton: true,
        animate: true,
        expandable: false,
        resizable: false,
        expandIcon: "fas fa-expand",
        compressIcon: "fas fa-compress"
    };
    
    ns.dialogs = {};

    ns.dialogs.heightDiff = null;

    ns.dialogs.openDialog = function(options) {
        var settings = $.extend({}, defaults, options);
        var $dialog = bootbox.dialog(settings);

        var $forms = $dialog.find("form");
        $forms.each(function (index, element) {
            $.validator.unobtrusive.parse($(element));
        });

        $dialog.find(".modal-dialog").draggable({
            handle: ".modal-header"
        });
        $(document).trigger("partialHtmlRender");
        
        if (settings.minHeight) {
            $dialog.find(".modal-body").css({ "min-height": settings.minHeight });
        }
        if (settings.width) {
            $dialog.find(".modal-dialog").css("width", settings.width);
        }
        if (settings.maxWidth) {
            $dialog.find(".modal-dialog").css("max-width", settings.maxWidth);
        }
        if (settings.minWidth) {
            $dialog.find(".modal-dialog").css("min-width", settings.minWidth);
        }

        if (settings.resizable) {
            initResizable($dialog);
        }

        if (settings.expandable) {
            initExpandable($dialog);
        }

        updateTitle($dialog);

        return $dialog;
    };

    function updateTitle($dialog) {
        var titleText = $dialog.find(".modal-dialog #modal-title").val();
        if (titleText)
            $dialog.find(".modal-dialog .modal-title").text(titleText);
    }

    function initExpandable($dialog) {
        var $expandIcon = $('<i id="expand-icon" />').addClass(defaults.expandIcon);
        var expandBtnHtml = '<button class="btn bootbox-expand-button pull-right"></button>';
        var $expandBtn = $(expandBtnHtml)
            .append($expandIcon)
            .on("click", function () { ns.dialogs.toggleExpand($dialog); });
        $dialog.find(".modal-dialog .modal-header .bootbox-close-button").after($expandBtn);
    }

    function initResizable($dialog) {
        var dialogBodyHeight = 600;
        var $dialogBody = $dialog.find(".bootbox-body");

        $dialog.data("resize-handlers", []);

        $dialog.find(".modal-content").resizable({
            minWidth: 1000,
            minHeight: dialogBodyHeight + 105,
            alsoResize: ".bootbox-body,.modal-dialog",
            resize: function (event, ui) {
                ns.dialogs.onResize($dialog, event, ui);
            } 
        });

        //$dialog.find(".modal-dialog").css({ "height": dialogBodyHeight + 105 });
        //$dialog.find(".modal-content").css({ "height": dialogBodyHeight + 105 });

        $dialog.find(".modal-footer").css({ "overflow": "auto" });

        $dialogBody.height(dialogBodyHeight);
    }

    ns.dialogs.onResize = function ($dialog, event, ui) {
        var handlers = $dialog.data("resize-handlers");
        handlers.forEach(function (handler) {
            if ($.isFunction(handler.callback)) {
                handler.callback(event, ui);
            }
        });
    };

    ns.dialogs.closeAllDialogs = function() {
        bootbox.hideAll();
    };

    ns.dialogs.closeDialog = function($dialog) {
        $dialog.modal("hide");
    };

    ns.dialogs.reload = function($dialog, callback) {
        $dialog.on("hidden.bs.modal", function () {
            $(this).removeData();
            callback();
        });
        $dialog.modal("hide");
    };

    ns.dialogs.toggleExpand = function ($dialog) {
        var isExpanded = $dialog.hasClass("expanded");
        var resizableElements = [".modal-dialog", ".modal-content", ".bootbox-body"];

        if (isExpanded) {
            if (ns.dialogs.heightDiff > 0) {
                _.each(resizableElements, function (el) {
                    var $el = $dialog.find(el);
                    $el.height($el.height() - ns.dialogs.heightDiff);
                });
            }

            $dialog.removeClass("expanded");
            $dialog.find(".modal-dialog").draggable("enable");
            $dialog.find(".modal-content").resizable("enable");
        } else {
            var availableHeight = $(window).height() - $dialog.find(".modal-content").height() - 20;
            ns.dialogs.heightDiff = availableHeight;

            if (availableHeight > 0) {
                _.each(resizableElements, function (el) {
                    var $el = $dialog.find(el);
                    $el.height($el.height() + availableHeight);
                });
            }

            $dialog.addClass("expanded");
            $dialog.find(".modal-dialog").draggable("disable");
            $dialog.find(".modal-content").resizable("disable");
        }

        $dialog.find("#expand-icon")
            .removeClass()
            .addClass(isExpanded ? defaults.expandIcon : defaults.compressIcon);

        ns.dialogs.onResize($dialog, null, { element: $dialog.find(".modal-content") });
    };

    ns.addInitFunction(function () {
        // Modal fixes

        $(document).on("show.bs.modal", ".modal", function () {
            var zIndex = 10400 + (10 * $(".modal:visible").length);
            $(this).css("z-index", zIndex);
            setTimeout(function () {
                $(".modal-backdrop").not(".modal-stack").css("z-index", zIndex - 1).addClass("modal-stack");
            }, 0);
        });

        $(document).on("hidden.bs.modal", ".modal", function () {
            $(".modal:visible").length && $(document.body).addClass("modal-open");
        });

    });


}($.app = $.app || {}));