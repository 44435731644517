/// <reference path="../app-core/app-core.js" />
/// <reference path="../app-globalize/app-globalize.js" />
/// <reference path="../app-core/app-number.js" />

(function (ns) {
    "use strict";

    //#region VALIDATORS

    var validators = Object.freeze({

        length: function ($input, maxLength) {
            var inputValue = $input.val();
            var length = inputValue.length;
            var isValid = (length <= maxLength);

            return isValid;
        },
        
        number: function($input) {
            var inputValue = $input.val();
            var parsedNumber = ns.parseNumber(inputValue);
            var isIntegerRequired = !!$input.attr("data-integer");

            var isInteger = /^\d+$/.test(inputValue);
            var isFloat = !isInteger && !isNaN(parsedNumber);
            var isNumber = isInteger || isFloat;
            var isContainSpaces = inputValue.indexOf(" ") >= 0;

            var isAllowableNumberType = (isIntegerRequired && isInteger) || (!isIntegerRequired && isNumber);
            var isValid = isAllowableNumberType && !isContainSpaces;
            return isValid;
        },

        numberInRange: function($input, minValueStr, maxValueStr) {
            var minValue = (!minValueStr) ? Number.MIN_VALUE : ns.parseNumber(minValueStr);
            var maxValue = (!maxValueStr) ? Number.MAX_VALUE : ns.parseNumber(maxValueStr);

            if (minValue < 0)
                return true;

            minValue = 0;

            var inputValue = $input.val();
            var parsedNumber = ns.parseNumber(inputValue);
            var isInRange = !!(parsedNumber >= minValue && parsedNumber <= maxValue);

            return isInRange;
        },

        precision: function($input) {
            var inputValue = $input.val();
            var parsedNumber = ns.parseNumber(inputValue);

            if (isNaN(parsedNumber)) {
                return false;
            }

            var decimalPlaces = getDecimalPlaces(inputValue);
            return decimalPlaces <= ns.config.maxDecimalPlaces;

            function getDecimalPlaces(numberString) {
                var separator = getSeparator(numberString);
                if (!separator) {
                    return 0;
                }

                var places = numberString.split(separator)[1].length;
                return places;

                function getSeparator(number) {
                    for (var i = 0; i < ns.config.decimalSeparators.length; i++) {
                        var separator = ns.config.decimalSeparators[i];
                        if (number.indexOf(separator) !== -1) {
                            return separator;
                        }
                    }
                    return null;
                }
            }
        }

    });

    //#endregion
    
    //#region SET HANDLERS

    ns.addInitFunction(function () {

        // Set old input value on focus
        $(document).on("focus", "input[data-val-number]:not([data-oldvalue]),input[data-val-length-max]:not([data-oldvalue])",
            function (e) {
                var $input = $(this);
                $input.attr("data-oldvalue", $input.val());
            });

        // Control input
        $(document).on("input change", "input[data-oldvalue]",
            function (e) {
                var $input = $(this);

                var inputValidators = getValidators($input);
                controlInput($input, inputValidators);
            });

        function getValidators($input) {
            var inputValidators = [];

            // Числовое значение
            if (hasAttribute($input, "data-val-number")) {
                inputValidators.push(validators.number, validators.precision);
            }

            // Максимальная длина строки
            if (hasAttribute($input, "data-val-length-max")) {
                var maxLength = +$input.attr("data-val-length-max");
                inputValidators.push([validators.length, maxLength]);
            }

            // Диапазон числовых значений
            if (hasAttribute($input, "data-val-range-min") && hasAttribute($input, "data-val-range-max")) {
                var minValueStr = $input.attr("data-val-range-min");
                var maxValueStr = $input.attr("data-val-range-max");
                inputValidators.push([validators.numberInRange, minValueStr, maxValueStr]);
            }

            // Диапазон числовых значений (условная валидация)
            if (hasAttribute($input, "data-val-rangeif-ruleparam")) {
                var parameters = $input.attr("data-val-rangeif-ruleparam");
                parameters = parameters.substring(1, parameters.length - 1);
                var arrayOfStrings = parameters.split(",");
                inputValidators.push([validators.numberInRange, arrayOfStrings[0], arrayOfStrings[1]]);
            }
            
            return inputValidators;
        }

        function hasAttribute($element, attributeName) {
            return !!$element.attr(attributeName);
        }

    });

    //#endregion
    
    //#region CONTROL INPUTS

    function controlInput($input, validators) {
        var isValid = true;
        
        if ($input.val().length > 0) {
            for (var i = 0; i < validators.length; i++) {
                var validationResult = getValidationResult($input, validators[i]);
                if (!validationResult) {
                    isValid = false;
                    break;
                }
            }
        }

        if (isValid) {
            $input.attr("data-oldvalue", $input.val()); // save input value
        } else {
            $input.val($input.attr("data-oldvalue"));   // restore old input value
            $input.trigger("input");
        }
        
        function getValidationResult($input, validator) {
            if ($.isFunction(validator)) {
                return validator($input);

            } else if ($.isArray(validator)) {
                var args = validator.slice(1);
                args.unshift($input);

                var validatorFunc = validator[0];
                return validatorFunc.apply(null, args);
            }
            return true;
        }
    }

    //#endregion
    
}($.app = $.app || {}));
