/**
 * Bootstrap Table Belarussian translation
 * Author: Evgeny Kuleshov <kuleshov.e@bdc.mtk.by>
 */
(function ($) {
    "use strict";

    (function (locale) {

        var translations = {
            formatLoadingMessage: function () {
                return "Калі ласка, пачакайце, ідзе загрузка...";
            },
            formatRecordsPerPage: function (pageNumber) {
                return pageNumber + " запісаў на старонку";
            },
            formatShowingRows: function (pageFrom, pageTo, totalRows) {
                return "Запісы з " + pageFrom + " па " + pageTo + " з " + totalRows;
            },
            formatSearch: function () {
                return "Пошук";
            },
            formatNoMatches: function () {
                return "Нічога не знойдзена";
            },
            formatRefresh: function () {
                return "Абнавіць";
            },
            formatToggle: function () {
                return "Пераключыць";
            },
            formatColumns: function () {
                return "Калонкі";
            },
            formatClearFilters: function () {
                return "Ачысціць фільтры";
            }
        };
        $.extend(locale, translations);

    }($.fn.bootstrapTable.locales["be"] = $.fn.bootstrapTable.locales["be"] || {}));

})(jQuery);

