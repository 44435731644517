/// <reference path="../app-core/app-core.js" />
/// <reference path="../app-core-widgets/app-messages.js" />

(function (ns) {
    "use strict";

    var isAjaxAnimationEnabled = true;

    ns.ajax = {};

    ns.ajax.enableGlobalAjaxAnimation = function () {
        isAjaxAnimationEnabled = true;
    };

    ns.ajax.disableGlobalAjaxAnimation = function () {
        isAjaxAnimationEnabled = false;
    };

    ns.ajax.processResponse = function (response, onSuccess, onFailure) {
        if (!response) return;

        if (response.isSuccess && onSuccess)
            onSuccess(response.content, response.redirectUrl);

        if (!response.isSuccess && onFailure)
            onFailure(response.content, response.redirectUrl);

        ns.messages.loadMessages();
    };

    ns.ajax.postJSON = function (url, data, successCallback) {
        $.ajax({
            url: url,
            method: "POST",
            contentType: "application/json; charset=utf-8",
            data: data,
            success: successCallback
        });
    };

    ns.addInitFunction(function () {

        $.ajaxSetup({ cache: false });

        var $body = $("body");
        var timer;
        
        // Анимация загрузки при длительных ajax-запросах (более 900 мс)
        $(document).on({
            ajaxStart: function () {
                if (isAjaxAnimationEnabled) {
                    //timer && clearTimeout(timer);
                    //timer = setTimeout(function () {
                    //    $body.addClass("loading");
                    //}, 900);
                    $body.addClass("loading");
                }
            },
            ajaxStop: function () {
                clearTimeout(timer);
                $body.removeClass("loading");
            },
            ajaxError: function (event, jqxhr, settings, thrownError) {
                try {
                    var responseObj = JSON.parse(jqxhr.responseText);
                    ns.messages.showErrorMessage(responseObj.ErrorMessage);
                } catch (e) {
                    console.log(thrownError);
                }
            }
        });

    });
    

}($.app = $.app || {}));