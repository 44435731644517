/// <reference path="../../app-common/app-core/app-core.js" />
/// <reference path="../../app-common/app-core/app-resources.js" />
/// <reference path="../../app-common/app-core-ajax/app-core-ajax.js" />
/// <reference path="../../app-common/app-core-widgets/app-dialog.js" />
/// <reference path="../../app-common/app-validation/app-validation-remote.js" />
/// <reference path="../app-core/app-config.js" />
/// <reference path="../../../bower_components/knockout/dist/knockout.debug.js" />

(function(ns) {
    "use strict";
    (function(organizations) {
        organizations.BranchViewModel = function(org, getBranchesUrl){
            var self = this;

            self.branches = ko.pureComputed(function() {
                if (org.organizationForm() === "BranchOfficeWithoutBankAccount") {
                    return $.ajax({
                        url: getBranchesUrl,
                        type: "POST",
                        data: {
                            unp: org.unp()
                        }
                    });
                } else {
                    return [];
                }
            }).extend({ async: org.organizationForm() === "BranchOfficeWithoutBankAccount" });

            self.selectedBranchSource = ko.observable("list");

            self.branchSource = ko.pureComputed(function() {
                return "new";
            });
            self.newBranchName = ko.observable().extend({
                required: {
                    params: true,
                    message: ns.resources.getResource("required"),
                    onlyIf: function() {
                        return self.branchSource() === "new";
                    }
                },
                remoteOrgNameValidationRule: {
                    params: {},
                    onlyIf: function() {
                        return self.branchSource() === "new";
                    }
                }
            });

            self.branchName = ko.pureComputed(function() {
                if (self.branchSource() === "list") {
                    return self.selectedBranch();
                } else if (self.branchSource() === "new") {
                    return self.newBranchName();
                } else {
                    return "-";
                }
            });

            self.parentOrgName = ko.pureComputed(function() {
                if (org.organizationForm() === "BranchOfficeWithoutBankAccount") {
                    return org.name(); // TODO через ajax
                } else {
                    return "-";
                }
            });
        }
    }(ns.organizations = ns.organizations || {}));
}($.app = $.app || {}));