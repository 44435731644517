/// <reference path="../app-core/app-core.js" />
/// <reference path="app-bootstrap.js" />


(function (ns) {
    "use strict";

    ns.addInitFunction(function () {
        bootbox.addLocale("ru", {
            OK: 'ОК',
            CANCEL: 'Отмена',
            CONFIRM: 'ОК'
        });
        bootbox.addLocale("be", {
            OK: 'ОК',
            CANCEL: 'Адмена',
            CONFIRM: 'ОК'
        });
        bootbox.setLocale(ns.config.locale);
    });

}($.app = $.app || {}));
