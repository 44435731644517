///<reference path="app-config.js" />
///<reference path="../app-core-ajax/app-core-ajax.js" />

(function (ns) {
    "use strict";

    ns.parseNumber = function (number, defaultValue) {
        if (typeof number === "string") {
            number = number.replace(",", ".").replace(" ", "");
        }
        if ($.isNumeric(number)) {
            return parseFloat(number);
        }

        if (defaultValue !== undefined) {
            return defaultValue;
        }
        return NaN;
    };

    ns.formatNumber = function (number) {
        return Globalize.formatNumber(number,
            { maximumFractionDigits: ns.config.maxDecimalPlaces, useGrouping: false });
    };


}($.app = $.app || {}));
