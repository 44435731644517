/// <reference path="../app-core/app-core.js" />
/// <reference path="app-bootstrap.js" />


(function (ns) {
    "use strict";

    ns.addInitFunction(function () {

        $(document).on('change', ':file', function () {
            var input = $(this),
                numFiles = input.get(0).files ? input.get(0).files.length : 1,
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            input.trigger('fileselect', [numFiles, label]);
        });

    });

}($.app = $.app || {}));
