/// <reference path="../app-core/app-core.js" />

(function (ns) {
    "use strict";

    (function (notifications) {

        function createNotification(msg) {
            var title = "ТКТС";
            var options = {
                body: msg
            }
            return new Notification(title, options);
        }

        notifications.showNotification = function (msg) {
            if (!("Notification" in window)) {
                return;
            }

            if (Notification.permission === "granted") {
                var notification = new createNotification(msg);
            }

            else if (Notification.permission !== "denied") {
                Notification.requestPermission(function (permission) {
                    if (permission === "granted") {
                        var notification = new createNotification(msg);
                    }
                });
            }
        }

        notifications.showNotificationIfHidden = function (msg) {
            var visibility = ns.visibility.get();
            if (visibility === "hidden") {
                notifications.showNotification(msg);
            }
        }

    }(ns.notifications = ns.notifications || {}));

}($.app = $.app || {}));
