///<reference path="app-config.js" />
///<reference path="app-resources.js" />
///<reference path="app-url.js" />
///<reference path="app-number.js" />

(function (ns) {
    "use strict";

    var initFunctions = [];
    
    function initApp() {
        for (var i = 0; i < initFunctions.length; i++) {
            initFunctions[i]();
        }
        initFunctions = undefined;
    };

    ns.initApp = function () {
        initApp();
    }
    
     /**
     * Adds initialization function, that should be
     * executed after page load only
     * @function
     * @param {Function} initFunction
     */
    ns.addInitFunction = function (initFunction) {
        initFunctions.push(initFunction);
    };

}($.app = $.app || {}));


