(function (ns) {
    "use strict";

    ns.date = {};

    ns.date.addDays = function (date, days, isInclusive) {
        var d = new Date(date);
        d.setDate(date.getDate() + days);
        if (isInclusive) {
            d.setDate(d.getDate() - 1);
        }
        return d;
    };

    ns.date.addMonths = function (date, months, isInclusive) {
        var d = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() !== d) {
            date.setDate(0);
        }
        if (isInclusive) {
            date.setDate(date.getDate() - 1);
        }
        return date;
    };

}($.app = $.app || {}));